.module-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #fffaeb;
}

.video-player{
  width: 100%;
  aspect-ratio: 16/9;
}

.module-content{
  width: 100vw;
}

.content-section {
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 100px;
  font-size: 1rem;
}

.module-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: -12rem 0 0 0;
}

.module-title {
  font-size: 46px;
  font-weight: bold;
  margin-bottom: -60px;
  margin-top: 0px;
  text-align: center;
}

.session-title {
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 40px;
  margin-top: 0%;
}
.ModuleOne-readings {
  background-image: url("./module1_pic1.webp");
  background-size: 35%;
  background-repeat: no-repeat;
  background-position: right -20% top -2%;
}

.ModuleTwo-readings {
  background-image: url("./module2_pic1.png");
  background-size: 20%;
  background-repeat: no-repeat;
  background-position: right 0% top 0%;
}
.ModuleThree-readings {
  background-image: url("./module3_pic1.png");
  background-size: 18%;
  background-repeat: no-repeat;
  background-position: right 0% top 0%;
}

.ModuleFour-readings {
  background-image: url("./module4_pic1.png");
  background-size: 18%;
  background-repeat: no-repeat;
  background-position: right 0% top 0%;
}

.ModuleOne-situations {
  background-image: url("./module1_pic2.webp");
  background-size: 75%;
  background-repeat: no-repeat;
  background-position: left 25% bottom 0px;
}
.ModuleTwo-situations {
  background-image: url("./module2_pic2.png");
  background-size: 34%;
  background-repeat: no-repeat;
  background-position: left 0% bottom 35%;
}
.ModuleThree-situations {
  background-image: url("./module3_pic2.png");
  background-size: 30%;
  background-repeat: no-repeat;
  background-position: left 5% bottom 35%;
}
.ModuleFour-situations {
  background-image: url("./module4_pic2.png");
  background-size: 33%;
  background-repeat: no-repeat;
  background-position: left 0% bottom 0%;
}

.ModuleOne-exercises {
  background-image: url("./module1_pic3.webp");
  background-size: 28%;
  background-repeat: no-repeat;
  background-position: right -5% top 0px;
}
.ModuleTwo-exercises {
  background-image: url("./module2_pic3.png");
  background-size: 25%;
  background-repeat: no-repeat;
  background-position: right 0% top 0px;
}
.ModuleThree-exercises {
  background-image: url("./module3_pic3.png");
  background-size: 25%;
  background-repeat: no-repeat;
  background-position: right 0% top 2%;
}
.ModuleFour-exercises {
  background-image: url("./module4_pic3.png");
  background-size: 25%;
  background-repeat: no-repeat;
  background-position: right 0% top 0%;
}

.session-title-paragraph {
  font-size: 1rem;
  font-weight: normal;
  text-align: center;
  margin-bottom: 40px;
  margin-left: 3%;
  margin-right: 3%;
  /* background-color: #faf4e1; */
  background-color: #fffaeb;
  border-radius: 5px;
}

.situation-paragraph {
  background-color: #faf4e1;;
  border-radius:5px;
}

.reading-title,
.exercise-title {
  margin-bottom: 0;
  width: 100%;
  min-height: 3rem;
  position: relative;
}
.reading-title {
  display: flex;
  flex-direction: column;
  justify-content: end;
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  line-height: 1.25;
}
.exercise-title {
  font-size: 22px;
  font-weight: 500;
  margin-top: 0.5rem;
  text-align: center;
  line-height: 1.25;
}
.reading-title-text {
  /* display: -webkit-box;
  -webkit-box-orient: vertical; */
  /* -webkit-line-clamp: 2; */
  overflow: hidden;
  /* position: absolute; */
  bottom: 0;
  width: 100%;
  left: 0;
  margin-bottom: 0.25rem;
}

.module-paragraph {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: justify;
}

.about-us-image {
  max-width: 100%;
}

img.about-us {
  width: 100%;
  object-fit: cover;
}
.expended-readings-container {
  width: 100%;
  padding: 5%;
  margin-bottom: 2rem;
}

.expended-exercise-container {
  width: 100%;
  padding: 5% 3%;
  margin-bottom: 6%;
}

.expended-exercise-image{
  display: flex;
  width: 100%;
  justify-content: center;
  margin: auto;
  margin-bottom: 1rem;
  aspect-ratio: 16/9;
  object-fit: cover;
}

.expended-exercise-audio{
  display: flex;
  width: 100%;
  justify-content: center;
  margin: auto;
  margin-bottom: 1rem;
}

.expended-readings-img {
  width: 100%;
  aspect-ratio: 5/3;
  overflow: hidden;
  object-fit: cover;
}
.expended-reading-title {
  margin-top: 1rem;
  /* padding: 1rem 0; */
  text-align: center;
  font-weight: 500;
  font-size: 22px;
}
.expended-exercise-title {
  font-weight: 500;
  font-size: 22px;
  text-align: center;
  padding: 0;
}

.expended-exercise-text {
  font-weight: 500;
  font-size: 1rem;
}

/* body {
  background-color: #fffaeb;
} */
.exercises-container,
.readings-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: auto;
}
.reading-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 0 3% 6%;
}
.exercise-container {
  width: 50%;
  padding: 0 1rem 1rem;
}
.module-title,
.readings-session,
.situations-session {
  padding-left: 28vw;
  padding-right: 12vw;
}
.exercises-session {
  padding-left: 27vw;
  padding-right: 11vw;
}

.exercises-session {
  padding-top: 50px;
}

.readings-session {
  padding-top: 130px;
}

.situations-session {
  background-color: #faf4e1;
  padding-top: 50px;
  padding-bottom: 50px;
}
.video-preview-image-container {
  border: 6px solid #6c6e87;
  border-radius: 0.3rem;
  position: relative;
}

.previewimages {
  width: 100%;
  aspect-ratio: 5/3;
  overflow: hidden;
  object-fit: cover;
}

.video-preview-image-container:hover,
.preview-image-container:hover {
  box-shadow: 0 8px 1rem 0 rgba(0, 0, 0, 0.24), 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  cursor: pointer;
}
.video-preview-image-container img {
  border-radius: 0rem;
}
.video-preview-image {
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
}
.play_button {
  position: absolute;
  z-index: 1;
  height: 30%;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.situations-session {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.story-cards-container {
  width: 83%;
  margin-bottom: clamp(1rem, 4vw, 5rem);
  position: relative;
  z-index: 10;
  height: max-content;
}
.story-cards-progress-bar {
  display: flex;
  width: 80%;
  /*margin-left: 3rem;*/
  flex-direction: row;
  justify-content: center;
}
.progress-bar-image {
  width: 100%;
  cursor: pointer;
}
.story-card1 {
  width: 100%;
  position: relative;
  z-index: 10;
}
.story-card2 {
  width: 96%;
  position: absolute;
  z-index: 9;
  top: 10%;
  left: 7%;
}
.story-card3 {
  width: 93%;
  position: absolute;
  z-index: 8;
  top: 16%;
  left: 13%;
}
.story-card4 {
  width: 87%;
  position: absolute;
  z-index: 7;
  top: 24%;
  left: 21%;
}
.next-button {
  width: 7%;
  position: absolute;
  z-index: 13;
  top: 50%;
  right: 4%;
  transform: translateY(-50%);
  cursor: pointer;
}
.prev-button {
  width: 7%;
  position: absolute;
  z-index: 13;
  top: 50%;
  left: -3%;
  transform: translateY(-50%);
  cursor: pointer;
}
.non-touch-device:hover {
  width: 9%;
  cursor: pointer;
}

.story-content {
  width: 91%;
  height: 91%;
  padding: 5% 7%;
  position: absolute;
  top: 0;
  z-index: 12;
  display: flex;
  flex-direction: column;
}
.story-number {
  position: absolute;
  z-index: 13;
  width: 3%;
  bottom: -2.5%;
  right: 9%;
  font-size: 6.5vw;
  color: #6c6d87;
  font-weight: bold;
}
.story-content-img-container {
  flex-shrink: 1;
  max-height: 100%;
}
.story-content-img-container img {
  border-radius: 1.5%;
}
.story-content-img {
  max-width: 100%;
}
.story-content-text {
  text-align: end;
  flex-shrink: 0;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.4;
  max-height: 35%;
  /* overflow: auto; */
  overflow-y: auto;
  margin-top: 0.5rem;
  padding-right: 0.5rem;
}
.story-content-text::-webkit-scrollbar {
  width: 5px;
  display: initial;
}
.story-content-text::-webkit-scrollbar-thumb {
  background-color:rgba(179, 176, 174, 0.8);
  border-radius: 5px;
}
@media only screen and (max-width: 768px) {
  .module-title{
    font-size: 32px;
  }
  .module-container {
    width: 100vw;
    margin: 12rem auto 3rem;
  }
  .module-title,
  .readings-session,
  .situations-session,
  .exercises-session {
    padding-left: 10vw;
    padding-right: 10vw;
  }
  .reading-title,
  .exercise-title {
    font-size: 22px;
  }
  .story-cards-container {
    align-self: flex-start;
    width: 100%;
  }
  .story-content-text {
    font-size: clamp(0.15rem, 1.6vw, 1rem);
  }

  /* .ModuleOne-readings {
    background-image: url("./module1_pic1.webp");
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: right -25% top 5%;
  }

  .ModuleTwo-readings {
    background-image: url("./module2_pic1.png");
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: right 0% top 0%;
  }
  .ModuleThree-readings {
    background-image: url("./module3_pic1.png");
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: right -25% top 5%;
  }
  .ModuleFour-readings {
    background-image: url("./module4_pic1.png");
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: right -25% top 5%;
  } */

  .ModuleOne-situations{
    background-image: url("./module1_pic2.webp");
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: left 25% top -4%;
  }
  .ModuleTwo-situations{
    background-image: url("./module2_pic2.png");
    background-size: 25%;
    background-repeat: no-repeat;
    background-position: left 5% top 7%;
  }
  .ModuleThree-situations{
    background-image: url("./module3_pic2.png");
    background-size: 25%;
    background-repeat: no-repeat;
    background-position: left 5% top 5%;
  }
  .ModuleFour-situations{
    background-image: url("./module4_pic2.png");
    background-size: 20%;
    background-repeat: no-repeat;
    background-position: left 10% top 2%;
  }

  .ModuleOne-exercises {
    background-image: url("./module1_pic3.webp");
    background-size: 35%;
    background-repeat: no-repeat;
    background-position: right 5% top 1%;
  }

  .ModuleTwo-exercises {
    background-image: url("./module2_pic3.png");
    background-size: 35%;
    background-repeat: no-repeat;
    background-position: right 5% top 1%;
  }
  .ModuleThree-exercises {
    background-image: url("./module3_pic3.png");
    background-size: 35%;
    background-repeat: no-repeat;
    background-position: right 5% top 1%;
  }
  .ModuleFour-exercises {
    background-image: url("./module4_pic3.png");
    background-size: 35%;
    background-repeat: no-repeat;
    background-position: right 5% top 1%;
  }
}

@media only screen and (max-width: 768px) {
  .exercise-container,
  .reading-container {
    width: 100%;
  }
  .story-cards-progress-bar {
    width: 80%;
  }
  .ModuleOne-readings{
    background-size: 40%;
    background-position: right -20% top 5%;
  }
  .ModuleTwo-readings{
    background-size: 40%;
    background-position: right -20% top 5%;
  }
  .ModuleThree-readings{
    background-size: 40%;
    background-position: right -20% top 5%;
  }
  .ModuleFour-readings{
    background-size: 40%;
    background-position: right 0% top 5%;
  }

  .story-content-text {
    font-size: 12px;
  }
}

@media only screen and (min-width: 487px) and (max-width: 768px) {
  .ModuleOne-situations {
    background-size: 35%;
    background-position: left 25% top -5%;
  }
  .ModuleTwo-situations {
    background-size: 25%;
    background-position: left 15% top 1%;
  }
  .ModuleThree-situations {
    background-size: 20%;
    background-position: left 20% top 1%;
  }
  .ModuleFour-situations {
    background-size: 15%;
    background-position: left 0% top 5%;
  }
}
