.training-card {
  width: 50%;
}

.training-basic-info {
  overflow: hidden;
}

.training-basic-info,
.training-info-expand,
.training-more-info {
  background-color: #eedfa3;
  width: 100%;

  border-top-left-radius: clamp(4px, 0.8vw, 10px);
  border-top-right-radius: clamp(4px, 0.8vw, 10px);
}

.training-basic-info:hover {
  box-shadow: 0 8px 1rem 0 rgba(0, 0, 0, 0.24), 0 4px 8px 0 rgba(0, 0, 0, 0.16) !important;
}
.training-description {
  padding: 10px;
  padding-right: 30px;
  padding-left: 1.4vw;
}

#image-container {
  align-items: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: clamp(4px, 0.8vw, 10px);
  aspect-ratio: 16/9;
  width: 100%;
  overflow: hidden;
  /* border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; */
}

/* .training-image-inner-container{
  
} */

.training-image {
  object-fit: cover;
  object-position: center center;
  width: 100%;
  aspect-ratio: 16/9;
}
.training-card-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /* -webkit-line-clamp: 2; */
  -moz-box-orient: vertical;
  -moz-box-align: start;
  -moz-box-sizing: border-box;
  -moz-line-clamp: 2;
  -ms-box-orient: vertical;
  -ms-box-align: start;
  -ms-box-sizing: border-box;
  -ms-line-clamp: 2;
  box-orient: vertical;
  box-align: start;
  box-sizing: border-box;
  line-clamp: 2;
  /* width: 85%; */
  margin: 0 2rem;
  font-weight: bold;
  font-size: 1.4rem;
  padding-top: 10px;
  /* height: 80px; */
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info-container {
  margin-top: 1vw;
  padding-bottom: 3%;
}

.info-container > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0% auto;
}
.training-time {
  font-size: 1rem;
  color: black;
}
.training-date {
  font-size: 1rem;
  color: black;
}

img.training-card-icon {
  width: 26px;
  height: 26px;
  margin: 0 4%;
}

.training-info-expand {
  margin-top: 2px;
  padding: 2% 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0 0 5px 5px;
  font-weight: bold;
  background-color: #eedfa3;
  width: 100%;
  font-size: 20px;
  cursor: pointer;
}

.training-more-info-backdrop {
  margin-top: 8px;
  padding: 0 0 0 15px;
  border-radius: 5px;
  background-color: #e2c067;
  min-height: 380px;
}

.training-more-info {
  position: relative;
  top: 1rem;
  border-radius: 5px;
  font-size: 20px;
  min-height: 400px;
}

.expand-short-info {
  padding-top: 3%;
  padding-right: 2.5vw;
}

.training-card > a {
  text-decoration: none;
}

.expand-short-info > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1% 0%;
}

img.expand-training-card-icon {
  margin: 0% 4%;
  width: 26px;
  height: 26px;
}

/* 
.training-organization {
}

.training-specialty {
} */

.training-event {
  line-height: 1.2em;
}

@media screen and (max-width: 1440px) {
  /* .training-basic-info {
  } */
}

@media screen and (max-width: 1225px) {
  /* .training-title {

  }  */
}

@media screen and (max-width: 1024px) {
  /* .training-title {
  }  

  .training-basic-info {
  } */

  .expand-short-info {
    font-size: 15px;
  }

  .training-description {
    font-size: 17px;
  }
}

@media screen and (max-width: 900px) {
  /* .training-title {
  }  */
}
@media screen and (max-width: 769px) {
  .training-card {
    width: 100%;
    margin: 2vw;
  }

  /* .training-title {
  }

  .training-basic-info {
  } */

  img.training-card-icon {
    width: 20px;
    height: 20px;
  }

  .training-info-expand {
    font-size: 14px;
  }

  .expand-more-info {
    font-size: 12px;
    padding-left: 5px;
  }

  img.expand-training-card-icon {
    width: 20px;
    height: 20px;
  }

  .training-description {
    font-size: 15px;
  }

  .expand-short-info {
    font-size: 12px;
    padding-right: 3vw;
  }
  .training-organization {
    margin-top: 8px;
  }
}

@media screen and (max-width: 585px) {
  .training-card {
    width: 100%;
    margin: 2vw;
  }

  /* .training-basic-info {
  } */

  .training-organization {
    margin-top: 0;
  }

  /* .training-title {
  } */
}

@media screen and (max-width: 500px) {
  /* .training-title {
  }  */
}

@media screen and (max-width: 430px) {
  /* 
  .training-title {
  }

  #image-container {
  } */

  .training-description {
    padding-left: 2.5vw;
  }

  .training-card-title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    -moz-box-orient: vertical;
    -moz-box-align: start;
    -moz-box-sizing: border-box;
    -moz-line-clamp: 3;
    -ms-box-orient: vertical;
    -ms-box-align: start;
    -ms-box-sizing: border-box;
    -ms-line-clamp: 3;
    box-orient: vertical;
    box-align: start;
    box-sizing: border-box;
    line-clamp: 3;
    font-size: 1rem;
  }
}
