.header {
    background-color: #f5f3ed;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 5rem;
    margin: 0%;
  
    /* Add responsive styles for smaller screens */
    @media screen and (max-width: 768px) {
      flex-direction: column;
      height: auto;
      padding: 10px;
    }
  }
  
  .headerTabs {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
  
    /* Add responsive styles for smaller screens */
    @media screen and (max-width: 768px) {
      width: 100%;
      margin-top: 10px;
    }
  }
  
  .smallMargin > * {
    margin: 0 10px;
  
    /* Add responsive styles for smaller screens */
    @media screen and (max-width: 768px) {
      margin: 10px 0;
    }
  }
  
  .backToTopPosBtn {
    background-color: aquamarine;
    width: 40px;
    height: 40px;
    bottom: 30px;
    position: fixed;
    right: 40px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    z-index: 99;
  
    /* Hide the button on smaller screens */
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  
  .button {
    justify-content: center;
    margin-left: auto;
  
    /* Add responsive styles for smaller screens */
    @media screen and (max-width: 768px) {
      margin-left: 0;
    }
  }
  
  .hrefA {
    margin: 0.6rem;
    text-decoration: none;
    color: black;
    cursor: pointer;
  
    /* Add responsive styles for smaller screens */
    @media screen and (max-width: 768px) {
      font-size: 0.8rem;
    }
  }
  
  .menuBtnStyle {
    color: black;
    text-decoration: underline;
  }

// .menuBtn:active {
//     color: green;
// }