.what-is-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* font-family: 'GenSenRounded TW'; */
  font-style: normal;
  background-color: #fffaeb;
}

.what-is-container {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-left: 20%;
  align-items: center;
  justify-content: center;
  /* 5% margin added */
}

.what-is-container-middle {
  background-color: #faf4e1;
}

.what-is-content {
  width: 70%;
  padding: 50px;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
}

#what-is-top-section {
  margin-top: 0;
}

.what-is-top-section h1 {
  padding: 10px;
  margin-bottom: 20px;
  font-weight: bold;
  font-weight: 700;
  font-size: 46px;
  line-height: 1.2;
}

.what-is-top-section p {
  width: 100%;
  margin-bottom: 20px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-weight: 400;
  font-size: 16px;
  line-height: 170%;
  text-align: start;
  /* word-break: break-all; */
}

.what-is-top-section .img-title {
  padding-bottom: 10px;
  text-align: center;
  overflow-wrap: anywhere;
  align-items: center;
}

.what-is-top-section img {
  width: 90%;
  /* padding-bottom: 10px; */
  object-fit: cover;
}

.what-is-middle-section h1 {
  padding: 10px;
  margin-bottom: 20px;
  font-weight: bold;
  font-weight: 700;
  font-size: 46px;
  line-height: 1.2;
  text-align: center;
}

.what-is-middle-section p {
  width: 100%;
  margin-bottom: 20px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.6;
  /* text-align: start; */
  /* word-break: break-all; */
}

.what-is-middle-section-img {
  width: 100vw;
  max-width: none;
  max-height: none;
  position: relative;
  left: -10vw;
}

.what-is-card-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-right: 2vw; */
}

.what-is-card-inner-container {
  display: flex;
  width: 100%;
  margin-top: 20px;
  justify-content: center;
}

.what-is-card-button-left {
  margin-right: 10px;
  border: none;
  background-color: transparent;
}

.what-is-card-button-right {
  margin-left: 10px;
  border: none;
  border-radius: 50%;
  background-color: transparent;
}

.what-is-card-content {
  width: 100%;
  max-width: 550px;
  height: auto;
  /* margin-bottom: 20px; */
  border-radius: 5px;
  padding: 1rem;
  position: relative;
  justify-content: center;
  background-color: #f5e6d7;
  overflow: scroll;
  /* border: 10px solid #f5e6d7; */
}

.what-is-card-body {
  display: flex;
  flex-direction: row;
  align-content: center;
  margin: auto;
  justify-content: center;
  overflow: auto;
  /* aspect-ratio: 4/3; */
  /* height: 410px; */
  /* height: 200px; */
}

.what-is-card-title {
  font-size: 22px;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  text-align: center;
}

.what-is-card-text-container {
  flex: 1;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: auto;
  /* vertical-align: middle; */
}

.what-is-evidence-content-text-time {
  display: flex;
  flex-direction: row;
  font-size: 1rem;
  font-weight: normal;
}

.what-is-evidence-content-text-time>p:first-child {
  flex: 0 1 auto;
}

.what-is-evidence-content-text-time>p:last-child {
  flex: 1 0 auto;
}

.what-is-card-content p {
  display: block;
  position: relative;
  margin: 0px;
  padding: 0px;
  padding-bottom: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 170%;
  text-align: left;
  overflow: scroll;
}

.what-is-card-img-container {
  flex: 1 1;
  display: flex;
  justify-content: center;
}

.what-is-card-content img {
  max-width: 70%;
  object-fit: contain;
}

.what-is-bottom-section h1 {
  padding: 10px;
  margin-bottom: 20px;
  font-weight: bold;
  font-weight: 700;
  font-size: 46px;
  line-height: 1.2;
}

.what-is-evidence-container {
  width: 100%;
  /* display: flex; */
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  /* flex-wrap: wrap; */
  /* flex-flow: row; */
  gap: 1rem;
  justify-content: start;
}

.what-is-evidence-content-container {
  /* width: 47%; */
  width: 100%;
  height: auto;
  border-radius: clamp(2px, 0.6vw, 5px);
  background-color: rgba(232, 200, 106, 0.5);
  display: flex;
  flex-direction: column;
}

.what-is-evidence-content-container>div:first-child {
  /* height: 10rem; */
  width: 100%;
  overflow: hidden;
}

.what-is-evidence-content-image {
  width: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
  object-position: center;
}

.what-is-evidence-content-text {
  height: fit-content;
  margin: 5%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  overflow-wrap: anywhere;
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  line-height: 131%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.what-is-evidence-content-text>h5 {
  height: auto;
  margin-bottom: 1rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /* -webkit-line-clamp: 2; */
  overflow: hidden;
  font-size: 22px;
}

.what-is-evidence-content-text>p {
  margin-bottom: 1rem;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
}

.what-is-evidence-content-text p span {
  max-width: 100%;
}

.what-is-evidence-content-text>p:first-of-type {
  height: auto;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /* -webkit-line-clamp: 3; */
  overflow: hidden;
  flex-grow: 1;
}

.what-is-evidence-content-text>p:nth-of-type(2) {
  height: fit-content;
}

.what-is-evidence-content-text>a {
  height: fit-content;
}

.what-is-evidence-content-text>a>img {
  height: 2rem;
  min-height: 25px;
}

.loadingPage {
  background-color: #fffaeb;
}

.article-button-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 1rem;
  margin-top: 1rem;
}

.article-button {
  margin-right: 10px;
  /* Add some margin between the images */
  width: 20px;
}

@media only screen and (min-width: 576px) {}

@media only screen and (max-width: 768px) {
  .what-is-top-section h1 {
    padding: 10px;
    margin-bottom: 20px;
    font-weight: bold;
    font-weight: 700;
    font-size: 32px;
    line-height: 1.2;
  }

  .what-is-card-body {
    display: flex;
    flex-direction: row;
    align-content: center;
    margin: auto;
    justify-content: center;
    overflow: auto;
    /* aspect-ratio: auto; */
    /* height: 410px; */
    /* height: 200px; */
  }

  .what-is-card-title {
    font-size: 22px;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    text-align: center;
  }

  .what-is-card-button-left {
    margin-right: 0px;
    border: none;
    background-color: transparent;
  }

  .what-is-card-button-right {
    margin-left: 0px;
    border: none;
    border-radius: 50%;
    background-color: transparent;
  }

  .what-is-card-inner-container {
    display: flex;
    width: 100%;
    margin-top: 20px;
    justify-content: center;
  }

  .what-is-container {
    width: 100%;
    padding: 5%;
    margin: 10px;
    margin-left: 0%;
  }

  .what-is-section {
    height: auto;
  }

  .what-is-content {
    width: 100%;
  }

  .what-is-content-section {
    width: 100%;
    height: auto;
  }

  .what-is-content {
    width: 100%;
    padding: 0px;
  }

  .what-is-middle-section-img {
    left: 0%;
  }

  /* .what-is-card-inner-container {
    height: 28rem;
  } */

  /* .what-is-card-content {
    height: 28rem;
    min-width: 230px;
    padding: 10px;
  } */

  /* .what-is-card-title {
    height: 5rem;
    padding-top: 20px;
    padding-bottom: 20px;
  } */

  .what-is-card-body {
    /* height: 25rem; */
    display: flex;
    flex-direction: column;
  }

  /* .what-is-card-text-container {
    top: 0;
    flex: 2;
    height: 20rem;
    padding-bottom: 30px;
  } */

  .what-is-card-img-container {
    flex: none;
    height: 8rem;
    margin-bottom: 15px;
  }

  .what-is-card-img-container img {
    width: 100%;
  }

  .what-is-card-button-left img {
    width: auto;
  }

  .what-is-card-button-right img {
    width: auto;
  }

  .what-is-evidence-content-container {
    width: 100%;
  }

  /* .what-is-evidence-content-container > div:first-child {
    width: 85vw;
  } */

  .what-is-evidence-content-text>h5 {
    height: auto;
    /* -webkit-line-clamp: 1; */
  }

  .what-is-evidence-content-text>p:first-of-type {
    /* -webkit-line-clamp: 2; */
  }
  
}

@media only screen and (min-width: 601px) and (max-width: 900px) {
  /* .what-is-content {
    width: 100%;
    padding-left: 0px;
  } */

  /* .what-is-middle-section-img {
    left: -10%;
  } */

  /* .what-is-card-content {
    min-width: 70%;
    height: 400px;
    padding: 20px;
  } */

  .what-is-card-content p {
    font-size: 16px;
  }

  .what-is-card-content img {
    width: 100%;
  }

  /* .what-is-evidence-content-container {
    width: 25vw;
  } */
  /* 
  .what-is-evidence-content-text {
    height: 30vh;
  } */

  /* .what-is-evidence-content-container > div:first-child {
    width: 25vw;
  } */

  .what-is-evidence-content-text>p:first-of-type {
    /* -webkit-line-clamp: 2; */
  }

  .what-is-evidence-content-text>h5 {
    height: auto;
    /* -webkit-line-clamp: 1; */
  }



}

@media only screen and (min-width: 769px) and (max-width: 1000px) {
  /* .what-is-middle-section-img {
    left: 0;
  } */

  .what-is-content {
    width: 90%;
  }

  .what-is-card-body {
    width: 100%;
    height: 380px;
  }

  /* 
  .what-is-card-content {
    height: 370px;
  } */
}

@media only screen and (min-width: 1000px) and (max-width: 1150px) {
  /* .what-is-middle-section-img {
    left: -16%;
  } */

  .what-is-content {
    width: 80%;
  }

  .what-is-card-body {
    width: 100%;
    height: 335px;
  }

  /* .what-is-card-content {
    height: 370px;
  } */

  /* .what-is-evidence-content-container {
    width: 23vw;
  } */

  /* .what-is-evidence-content-container > div:first-child {
    width: 23vw;
  } */
}

@media only screen and (min-width: 1150px) and (max-width: 1300px) {
  .what-is-content {
    width: 80%;
  }

  .what-is-card-body {
    width: 100%;
    height: 280px;
  }

}

@media only screen and (min-width: 1300px) {
  .what-is-card-body {
    aspect-ratio: 16/9;
  }
}

/* Responsive area */
/* Small devices (landscape phones, 576px and up) */
@media only screen and (min-width: 576px) {}

/* Medium devices (tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  
}

/* Large devices (desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .what-is-evidence-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* X-Large devices (large desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media only screen and (min-width: 1400px) {}