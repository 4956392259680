#counselor-content-section {
  width: 100%;
  text-align: center;
  position: relative;
  background-image: url("./line1.webp");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center 0;
}

#counselor-content-container {
  width: 80%;
  margin: -18rem 0 5rem 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#counselor-content-container > h1 {
  font-weight: bold;
  font-size: 46px;
  margin-bottom: 3rem;
}

#counselor-cards-container,
#team-cards-container,
#studentHelper-cards-container {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin-bottom: 50px;
}

@media only screen and (max-width: 768px) {
  #counselor-content-section {
    background-position: center 200px;
  }

  #counselor-content-container {
    width: 100%;
    margin-left: 0;
    margin-top: 15rem;
    font-size: 0.8rem;
  }
  #counselor-content-container > h1 {
    font-weight: bold;
    font-size: 32px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1200px) {
  #counselor-content-container {
    width: 80%;
    margin-left: 20%;
    /* margin-top: -20%; */
    font-size: 0.8rem;
  }
}
