body.popup-open {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

.holder {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: clip;
  background-color: #fffaeb;
}

#training-title {
  text-align: center;
  margin: 3.6rem;
  font-weight: 700;
  font-size: 46px;
  Line-height: 1.25em;
}

#training-content-container {
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
}




.arrow-container {
  display: flex;
  justify-content: center;
  column-gap: 2px;
}

.new-event-title {
  
  font-size: 28px;
  line-height: 1.25em;
  font-weight: 700;
  text-align: left;
}
.old-event-title {
  font-size: 32px;
  line-height: 1.25em;
  font-weight: 700;
  
}

.training-resource-title {
  /* margin-left: 1.5vw; */
  font-size: 32px;
  line-height: 1.25em;
  font-weight: 700;
}


/* #new-event-container,
#old-event-container {
  width: 75%;
  display: flex;
}
 */
.training-cardContainer {
  /* margin-left: 13vw; */
  width: 85%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  text-align: left;
}
.training-cardContainer > h3{
  text-align: left;
} 
.training-card-container {
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: left;
  position: relative;
  z-index: 0;
  gap: 10px;
  margin: 0 auto;
  /* margin-left: -5.5vw; */
  /* align-items: stretch; */
}




#slide-position-display {
  display: flex;
  align-items: center;
  font-size: 20px;
}

.card-button {
  border: none;
  background-color: transparent;
  width: 60px;
  height: 50px;
  padding: 0px;
}

#training-resource-container {
  padding-bottom: 1.5rem;
  width: 85%;
  margin: auto;
}
.training-resource-image-container{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

#training-resource-img {
  display: block;
  padding: 0 10%;
  width: 100%;
  z-index: 1; 
}




#training-resource-enter-section {
  position: absolute;
  top: 50%;
  left: 68%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.material-enter-container {
  position: relative;
  align-items: center;
  z-index: 2;
}

.material-center-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 2;
}

.training-material-enter-container {
  display: grid;
}

#training-enter-img {
  width: 12rem;
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  z-index: 2;
}

#training-enter-img:hover {
  cursor: pointer;
}

.training-enter-title {
  z-index: 2;
  font-size: 32px;
  line-height: 1.25rem;
  font-weight: 700;
  margin-bottom: 1rem;
}


.training-enter-text {
  z-index: 2;
  font-weight: bold;
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  text-align: center;
  align-self: center;
}

.training-enter-text:hover {
  cursor: pointer;
}
/* button#join-resource-button {
  width: 10%;
  height: 50px;
  background-color: #eedfa3;
  font-weight: bold;
  border-radius: clamp(4px, 3vw, 40px);
  font-size: 25px;
  border-color: transparent;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);
} */


.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
}

#popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
  z-index: 3;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: clamp(4px, 0.8vw, 10px);
  justify-content: center;
  
}

#training-popup-img {
  width: 100%;
  /* height: 25vw; */
}

#popup-text {
  position: absolute;
  top: 55%;
  /* left: 27%; */
  z-index: 1;
  font-weight: bold;
  font-size: 22px;
}


#popup-button {
  position: absolute;
    top: 75%;
    z-index: 1;
    width: 10rem;
    height: 2rem;
    background-color: #e8c86a;
    border-radius: clamp(4px, 0.5vw, 10px);
    font-size: 1rem;
    border-color: transparent;
    color: white;
}

.hidden {
  visibility: hidden;
}
/* .loadingPage {
  
} */

@media screen and (max-width: 1210px) {
  .training-enter-title {
    font-size: 2vw;
  }
  
  
  .training-enter-text {
    /* font-size: 2vw; */
    top: 67%;
  }
}


@media screen and (max-width: 1050px) {
  .training-enter-title {
    font-size: 1.5rem;
    padding-bottom: 0.7rem;
  }
  
  
  .training-enter-text {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 992px) {
  .training-resource-image-container{
    flex-direction: column;
  }

  #training-resource-enter-section {
    position: static;
    transform: none;
  }
}


@media screen and (max-width: 769px) {
  #training-title {
    font-size: 36px;
    margin: 2rem;
  }

  .new-event-title {
    font-size: 22px;
  }

  .old-event-title {
    font-size: 22px;
  }

  .training-resource-title {
    font-size: 22px;
  }
  .training-enter-title {
    font-size: 1.5rem;
  }
  
  
  .training-enter-text {
    font-size: 1.7rem;
  }

  #popup {
    width: 85%;
    max-width: 300px;
  }

}



@media screen and (max-width: 622px) {

  .training-material-enter-container {
    .training-enter-container {
      grid-template-columns: 1fr;
    }
  }

  .training-enter-title {
    font-size: 2vw;
    top: 10vw;
    margin-bottom: 0;
  }
  
  
  .training-enter-text {
    /* font-size: 2vw; */
    padding-top: 0.2rem;
    grid-row: 1 / 3;
    grid-column: 1 / 2;
    /* align-self: auto; */
  }

  #training-enter-img {
    grid-column: 1 / 2;
  }

}


@media screen and (max-width: 540px) {

  .training-enter-text {
    /* font-size: 2vw; */
    font-size: 1.3rem;
  }
}



@media screen and (max-width: 426px) {

  .training-enter-title {
    font-size: 1rem;
    top: 10vw;
    margin-bottom: 0;
  }

  .training-enter-text {
    font-size: 1rem;
  }
  
  #training-enter-img {
    width: 5rem;
  }

  #training-resource-img {
    padding: 0 0%;
  }


  /* #training-resource-container {

    
  } */
}

@media screen and (max-width: 768px) {
  #popup-text {
      font-size: 1rem;
    }
  
    #popup-button {
      position: absolute;
      top: 75%;
      z-index: 1;
      width: 8rem;
      height: 2rem;
      background-color: #e8c86a;
      border-radius: clamp(4px, 0.5vw, 10px);
      font-size: 1rem;
      border-color: transparent;
      color: white;
    }

}