/* global css */
.landing-container {
  display: flex;
  flex-direction: column;
  padding: 10vw;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  justify-content: center;
  align-items: center;
  font-family: "Open Sans", "Noto Serif TC", sans-serif;
  font-style: normal;
}

.landing-content-container {
  padding-top: 10%;
  padding-bottom: 10%;
}

.landing-arrow-prev,
.landing-arrow-next {
  cursor: pointer;
}

.landing-title {
  margin-bottom: 1.35rem;
  font-weight: 700;
  font-size: 36px;
  line-height: 131%;
  text-align: center;
  color: #404040;
}

.landing-title-little {
  font-weight: 700;
  font-size: 29px;
  line-height: 1.25em;
  display: flex;
  /* align-items: center; */
  color: #404040;
  word-wrap: break-word;
  text-align: center;
  /* word-break: break-all; */
  /* display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; */
}

.landing-box {
  margin-bottom: 1rem;
}

a.landing-para-little-box {
  color: #306188;
  max-width: 100%;
  word-break: break-word;
}

.landing-para {
  font-weight: 400;
  /* font-size: 20px; */
  font-size: 16px;
  line-height: 1.6;
  text-align: center;
  color: #404040;
  overflow: scroll;
  text-align: left;
}

div.landing-para,
div.landing-para-little {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

div.app-download-store {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
div.app-download-store img {
  max-width: 8rem;
}

.landing-little-para {
  font-weight: 400;
  /* font-size: 20px; */
  font-size: 12px;
  line-height: 1.6;
  text-align: center;
  color: #404040;
  overflow: scroll;
}

@media only screen and (min-width: 768px) {
  .landing-para {
    /* width: 85%; */
  }
  div.app-download-store img {
    max-width: 10rem;
  }
}

@media only screen and (min-width: 1200px) {
  div.app-download-store img {
    max-width: 14rem;
  }
}

.landing-para-little {
  font-weight: 400;
  /* font-size: 16px; */
  font-size: 1rem;
  /* line-height: 20px; */
  /* height: calc(1.31em*2); */
  color: #404040;
}

.landing-arrow {
  border: none;
  background-color: #fffaeb;
}

.landing-arrow img {
  pointer-events: none;
}

/* header css */

.landing-header {
  background-color: #faf4e1b3;
  position: sticky;
  top: 0;
  z-index: 999;
  backdrop-filter: blur(6px);
}

/* video css */

.landing-video-container {
  overflow: hidden;
  /* aspect-ratio: 16/9; */
  /* pointer-events: none; */
  background-color: #faf4e1;
  padding-top: 0;
}

/* the full width and cut part of video version*/
/* adjust the ratio in 76vw*{ratio} set display how height of the video*/
/* comment this div css, and iframe calc height and uncomment height to old version */
.landing-video-container div {
  /* height: calc(50vw*0.5/1.77); */
  height: calc(120vw * 0.5 / 1.77);
  overflow: hidden;
}

.landing-video-container > div > video {
  position: relative;
  width: 100vw;
  height: unset;
}

/* .landing-video-container iframe {
    position: relative;
    width: 200vw;
    height: calc(76vw/1.77);
    height: 30vw;
} */

.landing-video-container p {
  padding-top: 0.6rem;
  font-size: 1rem;
  text-align: left;
  margin-bottom: 0%;
}

/* publicity css */

.landing-publicity-container {
  background-color: #fffaeb;
}

.landing-publicity-content {
  width: 100%;
  /* height: 26vw; */
  display: flex;
}

.landing-publicity-box-left {
  width: 47%;
  margin-right: 3%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-pagination-bullet {
  width: 1rem !important;
  height: 1rem !important;
  margin: 0 5px;
}

/* .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  } */

.landing-publicity-box-left-img {
  width: 100%;
  aspect-ratio: 16/9;
  /* height: 21vw; */
  object-fit: cover;
  object-position: center;
}

.landing-publicity-box-left-bottom {
  height: 5vw;
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 25fr 1fr;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.landing-publicity-scroll {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.landing-publicity-box-right {
  width: 47%;
  margin-left: 3%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media screen and (min-width: 993px) {
  .landing-publicity-box-right {
    /* aspect-ratio: 16/9; */
  }

  .landing-publicity-box-right h2 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    /* -webkit-line-clamp: 2; */
    /* height: calc(1.25em*2); */
  }

  .landing-publicity-box-right-para {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    /* height: calc(1.6em * 4); */
    text-align: start;
    line-height: 1.6;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .landing-publicity-box-right-para p {
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.landing-publicity-box-right h2 {
  /* height: 3vw; */
  margin-bottom: 1rem;
  width: 100%;
  overflow: hidden;
  text-align: left;
  /* white-space: nowrap; */
}

.landing-publicity-box-right > div:first-of-type {
  /* height: 8vw; */
  display: flex;
  /* -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    height: calc(1.5em * 2);
    text-align: start;
    line-height: 1.5;
    overflow: hidden; */
}

/* .landing-publicity-box-right-para p {
    height: 16vw;
} */

/* .landing-publicity-box-right-para p::after {
    content: '...';
    position: absolute;
    bottom: 0;
    right: 0;
} */

/* .landing-publicity-box-right p {
    position: relative;
    margin-bottom: 0px;
    line-height: 170%;
    text-align: start;
} */

.landing-publicity-box-right > a {
  height: 2vw;
}

.landing-publicity-box-right a p {
  margin-top: 10px;
  margin-bottom: 0%;
  text-decoration-line: underline;
  color: #dc894e;
  height: fit-content;
  line-height: normal;
}

.landing-smart-box {
  margin-top: 1rem;
  /* height: 2vw; */
  display: flex;
}

.landing-smart-box img {
  width: clamp(13px, 1.3vw, 19px);
  height: clamp(13px, 1.3vw, 19px);
  margin-right: 5px;
}

.landing-smart-box p {
  margin-top: 0px;
  color: #e2c067;
  height: fit-content;
  margin-bottom: 0%;
}

/* training css */

.landing-training-container {
  background-color: #faf4e1;
}

.landing-training-content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-training-img {
  width: 49%;
  margin-right: 1%;
}

.landing-training-box-container {
  width: 49%;
  /* height: 28vw; */
  /* max-height: 420px; */
  margin-left: 1%;
  padding: 2rem;
  padding-bottom: 1.5rem;
  background: #eedfa3;
  border-radius: 30px;
}

.landing-training-box {
  width: 100%;
  aspect-ratio: 16/9;
  /* overflow: auto; */
  overflow-y: auto;
  max-height: 400px;
}

.landing-training-box-img {
  height: calc(36vw * (314 / 658));
}

.landing-training-box::-webkit-scrollbar {
  width: 5px;
  display: initial;
}

.landing-training-box::-webkit-scrollbar-thumb {
  background-color: rgba(179, 176, 174, 0.8);
  border-radius: 5px;
}

/* .landing-training-box::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

.landing-training-box::-webkit-scrollbar-thumb {
    background: #d8d6d6;
    border-radius: 10px;
}

.landing-training-box::-webkit-scrollbar-thumb:hover {
    background: #3a3a3a;
} */

.landing-training-box-content {
  height: fit-content;
  display: grid;
  grid-template-columns: 1fr 14fr;
  column-gap: 2%;
}

.landing-training-box-content-left {
  width: 4px;
  margin-left: 11px;
  display: flex;
  justify-content: center;
  align-items: start;
  background-color: #e2c067;
  padding-bottom: 2rem;
}

.landing-training-box-content-left img {
  width: 26px;
  height: 25px;
}

.landing-training-box-content-right {
  display: flex;
  flex-direction: column;
  position: relative;
  /* top: 40%; */
  /* height: 4.2vw; */
  text-align: start;
  justify-content: center;
  padding-bottom: 1.5rem;
}

.landing-training-box-content-right > p:first-of-type {
  height: auto;
  margin-bottom: 0%;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0;
  color: #676767;
}

.landing-training-box-content-right > a {
  /* display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; */
  /* height: 3.5vw; */
  margin-bottom: 0px;
  /* padding-top: 5px; */
  font-size: 18px;
  font-weight: 500;
  line-height: 160%;
  color: #404040;
  overflow: auto;
}

/* ::-webkit-scrollbar{
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color:rgba(179, 176, 174, 0.8);
    border-radius: 5px;
  } */

/* research css */

.landing-research-container {
  background-color: #fffaeb;
}

.landing-research-content {
  width: 90%;
  min-height: 18rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 3%;
  align-content: center;
  align-items: stretch;
}

.landing-research-containcontent {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}

.landing-research-containcontent > button {
  padding: 0% 1rem;
  /* min-width: 35px; */
}

/* .landing-research-content>button:first-child {
    grid-column-start: 1;
    grid-column-end: 2;
} */

/* .landing-research-content>button:last-child {
    grid-column-start: 5;
    grid-column-end: 6;
} */

.swiper-pagination-bullet-active {
  background-color: #e2c067 !important;
}

.landing-research-card {
  height: inherit;
  padding: 5%;
  display: flex;
  flex-direction: column;
  background-color: #eedfa3;
  border-radius: 18.5203px;
}

.landing-research-card > img {
  width: 100%;
  aspect-ratio: 1/1;
  /* height: 22vh; */
  margin-bottom: 5%;
  border-radius: 18.5203px;
  object-fit: cover;
  object-position: center;
}

.landing-research-card > h1 {
  /* height: 8vh; */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /* -webkit-line-clamp: 2; */
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  overflow: hidden;
  line-height: 1.6;
  /* height: calc(1.6em * 2); */
  margin-bottom: 1rem;
  flex-grow: 1;
  /* color: #404040; */
}

.landing-research-card > p {
  height: 1.5rem;
  margin-bottom: 1rem;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 131%;
  color: #404040;
}

.landing-research-card > a {
  width: fit-content;
  height: 1.5rem;
}

.landing-research-card > a > button {
  width: 100%;
  border: none;
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 131%;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  color: #404040;
  border-radius: 7px;
  background-color: #e2c067;
}

/* order css */

.landing-order-container {
  background-color: #faf4e1;
}

.landing-order-content {
  width: 100%;
  margin-top: 4rem;
  display: grid;
  grid-template-columns: 1fr 24% 30% 24% 1fr;
  column-gap: 4%;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.landing-order-content > button:first-child {
  grid-column-start: 1;
  grid-column-end: 2;
}

.landing-order-content > button:last-child {
  grid-column-start: 5;
  grid-column-end: 6;
}

.landing-order-content > button {
  background-color: #faf4e1;
}

.landing-order-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4rem;
  background-color: #eedfa3;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
  border-radius: 30px;
  /* max-width:350px ; */
  margin: auto;
}

.landing-order-card-large {
  width: 100%;
  height: auto;
  padding-top: 4rem;
  max-width: 400px;
  /* aspect-ratio: 1/1; */
}

.landing-order-card-large > div,
.landing-order-card-small > div {
  width: 80%;
  aspect-ratio: 1.4/1;
}

@media only screen and (max-width: 1023px) {
  .landing-order-card-large > div {
    aspect-ratio: initial;
  }
}

.landing-order-card-small {
  width: 100%;
  max-width: 350px;
  font-size: 14px;
}
.landing-order-card-small > div {
  overflow: auto;
  aspect-ratio: 1.2/1 !important;
}

.landing-order-img {
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.landing-order-card > h1 {
  /* height: 3rem; */
  margin-top: 1rem;
}

.landing-order-card > p:first-of-type {
  word-break: break-word;
  text-align: center;
  height: 3rem;
  margin-bottom: 0;
  font-weight: 500;
  /* line-height: 26px; */
  padding-left: 1rem;
  padding-right: 1rem;
}

.landing-order-card > div {
  /* display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5; */
  /* max-height: 12rem; */
  margin-bottom: 0;
  padding-left: 3%;
  padding-right: 3%;
  align-items: center;
  text-align: center;
  overflow: auto;
}

.landing-order-card > p:nth-of-type(2)::-webkit-scrollbar {
  display: none;
}

.landing-order-card > img {
  height: 2rem;
  position: relative;
}

.landing-order-card > img:first-child {
  width: 8rem;
  height: 8rem;
  top: -8rem;
  margin-bottom: -8rem;
}

.landing-order-card > img:nth-of-type(2) {
  left: -40%;
}

.landing-order-card > img:nth-of-type(3) {
  right: -40%;
}

.landing-order-card > img:last-child {
  max-width: 80%;
  max-height: 8rem;
  height: 4rem;
  flex-grow: 1;
  object-fit: contain;
  margin-bottom: 0.5rem;
}

.landing-order-card-small > img:first-child {
  width: 6rem;
  height: 6rem;
  top: -6rem;
  margin-bottom: -6rem;
}

.landing-order-card-small > h1 {
  /* height: 1.5rem; */
  font-size: 22px;
  word-break: break-word;
}

.landing-order-card-small > p:first-of-type {
  height: 2.7rem;
}

.landing-order-card-small > p:nth-of-type(2) {
  max-height: 8rem;
  line-height: 28px;
}

.landing-order-card-small > img {
  height: 1.5rem;
  position: relative;
}

.landing-order-card-small > img:last-child {
  max-width: 80%;
  max-height: 6rem;
}

.landing-title-order {
  font-weight: 700;
  font-size: 26.7599px;
  line-height: 131%;
  text-align: center;
  letter-spacing: 0.06em;
  color: #000000;
}

.landing-para-order {
  font-weight: 700;
  font-size: 18px;
  line-height: 131%;
  text-align: center;
  letter-spacing: 0.06em;
  color: #000000;
}

.landing-order-para-little {
  padding-left: 5%;
  padding-right: 5%;
  font-weight: 700;
  font-size: 10px;
  line-height: 170%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #404040;
}

.landing-order-para {
  padding: 5%;
  margin-bottom: 0px;
  text-align: center;
}

/* app css */

.landing-app-container {
  background-color: #fffaeb;
}

.landing-app-content {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.landing-app-content > img {
  width: 47%;
  margin-right: 3%;
  align-self: flex-start;
}

.landing-app-box {
  width: 47%;
  /* height: calc(37.6vw * (720 / 644)); */
  margin-left: 3%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.landing-app-box > p {
  line-height: 26px;
  color: #000000;
  text-align: justify;
}

/* bottom css */

.landing-bottom-container {
  flex-direction: row;
  background-color: #faf4e1;
}

.landing-bottom-container > img {
  width: 47%;
  margin-right: 3%;
}

.landing-bottom-box {
  width: 47%;
  /* height: calc(37.6vw*(300/576)); */
  margin-left: 3%;
  padding: 2%;
  display: grid;
  grid-template-columns: 1fr 22fr;
  column-gap: 5%;
  row-gap: 2%;
  align-items: center;
  background: #eedfa3;
  border-radius: 30px;
}

.landing-bottom-box > h1 {
  grid-column-start: 1;
  grid-column-end: 3;
}

.landing-bottom-box > img {
  grid-column-start: 1;
  grid-column-end: 2;
  align-self: self-start;
}

.landing-bottom-box > p {
  grid-column-start: 2;
  grid-column-end: 3;
  /* margin-top: 2%;
  margin-bottom: 2%; */
  font-weight: 500;
}
@media only screen and (max-width: 1025px) {
  .landing-order-card-small > h1 {
    font-size: 18px;
  }
}
/* mobile view */
@media only screen and (max-width: 992px) {
  .landing-training-box-content-right {
    padding-bottom: 1rem;
  }
  .landing-training-box {
    width: 100%;
    aspect-ratio: 16/9;
    overflow: auto;
  }

  /* global css */
  .landing-publicity-box-right-para {
    /* height: 8vw; */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    height: fit-content;
    /* -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        height: calc(1.5em * 2);
        text-align: start;
        line-height: 1.5;
        overflow: hidden; */
  }

  .landing-title-little {
    font-size: 28px;
    /* align-items: center; */
  }

  /* .landing-para {
        font-size: clamp(18px, 2.5vw, 24px);
    } */

  .landing-para-little {
    font-size: clamp(14px, 2vw, 19px);
  }

  /* video css */
  .landing-video-container iframe {
    height: 20rem;
  }

  /* publicity css */
  .landing-publicity-content {
    height: max-content;
    flex-direction: column;
  }

  .landing-publicity-box-left {
    width: 80vw;
    height: auto;
    margin-right: 0;
    margin-bottom: 1.5rem;
  }

  /* .landing-publicity-box-left-img {
        height: 23vh;
    } */

  .landing-publicity-box-left-bottom {
    height: 3rem;
  }

  .landing-publicity-box-right {
    width: 80vw;
    margin-left: 0%;
  }

  .landing-publicity-box-right h2 {
    height: auto;
    min-height: 3rem;
    margin-bottom: 1rem;
    white-space: unset;
  }
  /* 
    .landing-publicity-box-right>a {
        height: 3vh;
    } */

  .landing-publicity-box-right a p {
    margin-bottom: 0px;
  }

  /* .landing-publicity-box-right>div:nth-of-type(2) {
        margin-top: 3vh;
        height: 3vh;
    } */

  /* training css */
  .landing-training-content {
    flex-direction: column;
  }

  .landing-training-img {
    width: 80vw;
    margin-right: 0;
  }

  .landing-training-box-container {
    width: 100%;
    /* height: 20rem; */
    padding: 1rem;
  }

  /* .landing-training-box-content {
        height: 10.9vh;
    } */

  .landing-training-box-content-right > p:first-of-type {
    height: 1.5rem;
    margin-bottom: 0.2rem;
  }

  /* research css */
  .landing-research-content {
    grid-template-columns: 1fr 1fr;
  }

  .landing-research-content > div:nth-of-type(3) {
    display: none;
  }

  /* .landing-research-content>button:last-child {
        grid-column-start: 4;
        grid-column-end: 5;
    } */

  /* order css */
  .landing-order-content {
    grid-template-columns: 1fr 18fr 1fr;
  }

  .landing-order-card-small {
    display: none;
  }

  .landing-order-content > button:last-child {
    grid-column-start: 3;
    grid-column-end: 4;
  }

  /* app css */

  .landing-app-content {
    flex-direction: column;
  }

  .landing-app-content > img {
    width: 100%;
    margin-right: 0%;
    margin-bottom: 3%;
  }

  .landing-app-box {
    width: 100%;
    height: auto;
    margin-left: 0%;
    /* padding: 4%; */
  }

  /* bottom css */
  .landing-bottom-container {
    flex-direction: column;
  }

  .landing-bottom-container > img {
    width: 100%;
    margin-right: 0%;
    margin-bottom: 3%;
  }

  .landing-bottom-box {
    width: 100%;
    height: auto;
    margin-left: 0%;
    padding: 4%;
    padding-bottom: 7%;
  }

  @media only screen and (max-width: 768px) {
    .landing-training-box-content-right > a {
      font-size: 16px;
    }

    .landing-arrow-prev,
    .landing-arrow-next {
      max-width: 1rem;
    }

    /* training css */
    .landing-training-box-content {
      column-gap: 10px;
    }

    /* research css */
    .landing-research-content {
      grid-template-columns: 1fr;
    }

    .landing-arrow-image {
      width: 100%;
      max-width: 1.5rem;
    }

    .landing-research-content > div:nth-of-type(2) {
      display: none;
    }

    .landing-research-containcontent > button {
      padding: 0% 0.5rem;
    }

    /* .landing-research-content>button:last-child {
            grid-column-start: 3;
            grid-column-end: 4;
        } */

    @media only screen and (max-width: 500px) {
      .landing-training-box {
        width: 100%;
        aspect-ratio: 1/1;
        overflow: auto;
      }

      /* bottom css */
      .landing-bottom-box {
        row-gap: 0;
      }
      .landing-title-little {
        font-size: 20px;
      }
      .landing-para {
        font-size: 16px;
      }

      .landing-order-card-large {
        width: 300px;
      }

      .landing-research-containcontent {
        width: 90vw;
      }
    }

    @media only screen and (max-width: 376px) {
      .landing-order-card-large {
        width: 280px;
      }
    }
  }
}
@media only screen and (max-width: 376px) {
  .landing-order-card-large {
    width: 265px;
  }
}

@media only screen and (max-width: 321px) {
  .landing-order-card-large {
    width: 215px;
  }
}
@media only screen and (min-width: 993px) and (max-width: 1200px) {
  /* .landing-container {
        padding: 5vw;
    } */

  /* training css */
  /* .landing-training-box-content {
        height: 7.1vw;
    } */

  /* .landing-training-box-content-right>a {
        font-size: 13px;
    } */

  /* research css */
  .landing-research-containcontent {
    width: 90vw;
  }

  /* order css */
  .landing-order-content {
    width: 90vw;
  }

  /* app css */
  .landing-app-box {
    /* height: calc(42.3vw * (720 / 644)); */
  }

  /* bottom css */
  /* .landing-bottom-box {
        height: calc(42.3vw*(300/576));
    } */
}

.landing-order-card-small > div {
  max-height: 14rem;
}

/* Responsive area */
/* Small devices (landscape phones, 576px and up) */
@media only screen and (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .landing-app-content > img {
    position: sticky;
    top: 12.75rem;
  }
}

/* X-Large devices (large desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media only screen and (min-width: 1400px) {
}
