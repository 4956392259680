.researches-info-container {
  padding: 1%;
  width: 33.3%;
}
.researches-info {
  display: flex;
  background: rgba(232, 200, 106, 0.5);
  padding: 1rem 1rem 1rem;
  flex-direction: column;
  border-radius: clamp(4px, 0.8vw, 10px);
  min-height: clamp(200px, 40vw, 700px);
  height: 100%;
  justify-content: flex-start;
  /* align-items: center; */
  align-items: flex-start;
  overflow: hidden;
}
/* .researches-image {
  width: 100%;
  border-radius: clamp(2px, 0.6vw, 5px);
} */

.researches-image-container-container {
width: 100%;
  
}

.researches-image-container {
  width: 100%; /* Adjust the height as per your requirements */
  aspect-ratio: 1/1;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.researches-image {
  overflow: hidden;
  object-fit: cover;
  object-position: center 50%;
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: clamp(2px, 0.6vw, 5px);
}
.researches-info-contents {
  text-align: left;
  padding: 1rem 0%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.researches-title {
  font-weight: 700;
  font-size: 1rem !important;
  flex-grow: 1;
}

@media only screen and (max-width: 768px) {
  .researches-info-container {
    width: 100%;
    padding: 3% 1.5%;
  }
}
.researches-info:hover {
  box-shadow: 0 8px 1rem 0 rgba(0, 0, 0, 0.24), 0 4px 8px 0 rgba(0, 0, 0, 0.16);
}

/* @media screen and (min-width: 1920px) {  
  .researches-info-container {
    padding: 2%;
    width: 33%;
  }
  
  .researches-info {
    padding: 4.5vw 3.3vw 2vw;
    min-height: 400px;
  }
  
  .researches-info-img {
    border-radius: 10px;
  }
  
  .researches-info-contents {
    padding: 5% 4% 0%;
  }
    
  .researches-title {
    font-size: 1.2rem !important;
  }
} */

@media only screen and (min-width:600px) and (max-width: 1000px){
  .researches-info-container {
    padding: 1%;
    width: 50%;
  }
}