.section-bar {
  position: sticky;
  top: 20px;
  width: 20%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 240px;
}

.meet-us-section-bar {
  position: sticky;
  top: 20px;
  width: 20%;
  /* margin-top: 10px; */
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 320px;
}

.section-bar-container {
  width: 150px;
  border-radius: 5px;
  background-color: #f5e6d7;
}

.section {
  display: flex;
  height:80px;
  width: 150px;
  padding: 1rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 500;
  font-family: "Open Sans","Noto Serif TC",sans-serif;
}

.section:first-child {
  border-radius: 5px 5px 0 0;
}

.section:last-child {
  border-radius: 0 0 5px 5px;
}

.section.current {
  background-color: #f5df8f;
  font-size: 1rem;
  font-weight: 500;
  font-family: "Open Sans","Noto Serif TC",sans-serif;
}

.section:hover {
  background-color: #f5df8f;
  cursor: pointer;
}

.section-bar.scroll-up {
  position: fixed;
  top: 0;
  margin-top: 0;
  z-index: 1000;
}

@media only screen and (max-width: 768px) {
  .section-bar {
    width: 100%;
    margin: 5px 0 0 0;
    align-items: center;
    position: absolute;
  }

  .meet-us-section-bar  {
    width: 100%;
    margin: 5px 0 0 0;
    align-items: center;
    position: absolute;
  }

  .section-bar-container {
    width: 90%;
  }

  .section {
    width: 100%;
    padding: 0.5rem 1.5rem;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    border-bottom: 1px solid #fffaeb;
  }

  .section:last-child {
    border-bottom: none;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1200px) {
  .section-bar {
    width: 25%;
  }

  .section-bar-container {
    width: fit-content;
  }

  .section {
    width: 130px;
  }
}

@media only screen and (min-width: 1200px) {
  .section:first-child {
    border-radius: 5px 5px 0 0;
  }

  .section:last-child {
    border-radius: 0 0 5px 5px;
  }
}
