/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

@font-face {
  font-family: GenSenRounded;
  src: url("fonts/GenSenRounded-R.ttf") format("truetype");
}
@font-face {
  font-family: GenSenRounded;
  src: url("fonts/GenSenRounded-B.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: GenSenRounded;
  src: url("fonts/GenSenRounded-H.ttf") format("truetype");
  font-weight: 900;
}
@font-face {
  font-family: GenSenRounded;
  src: url("fonts/GenSenRounded-M.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: GenSenRounded;
  src: url("fonts/GenSenRounded-L.ttf") format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: GenSenRounded;
  src: url("fonts/GenSenRounded-EL.ttf") format("truetype");
  font-weight: 100;
}

/* Import the "Open Sans" font family with various font weights */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@200;300;400;500;600;700;800;900&display=swap');

/* iframe {
  display: none;
} */

body,
p,
a,
span,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Open Sans","Noto Serif TC",sans-serif!important;
  color: #404040;
  /* word-break: break-all; */
}

::-webkit-scrollbar{
  display: none;
}

img{
  max-width: 1500px;
  max-height: 1500px;
}

a {
  text-decoration: none !important;
}

/* p {
  word-break: break-all;
} */

.fadeIn {
  animation: fadeInAnimation 350ms ease-out;
}

.fadeOut {
  animation: fadeOutAnimation 350ms ease-out;
}

@keyframes fadeInAnimation {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOutAnimation {
  from { opacity: 1; }
  to { opacity: 0; }
}
