.publicity_sub_wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #fffaeb;
}

.ps-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 50px 0 2rem 0;
}
.ps-image-slider-page-number {
  font-size: 1rem;
}

.ps-content {
  margin: 30px auto;
  width: 62.6vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  text-justify: auto;
}

.ps-title {
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 40px;
}

.ps-multimedia-container,
.ps-contents-container {
  width: 100%;
  background-color: rgba(232, 200, 106, 0.5);
  padding: 1.5rem;
  margin-bottom: 2rem;
}

/* .ps-contents-container p,
div {
  font-family: Microsoft JhengHei;
} */

.ps-multimedia-title {
  margin-bottom: 2vw;
  font-size: clamp(16px, 1.4rem, 32px);
}
.ps-contents-container {
  padding: 1rem 1rem;
}
.ps-callout-title,
.ps-multimedia-title {
  /* font-size: 2.2vw; */
  text-align: left;
  /* margin-left: 2vw; */
  color: #000;
}
.ps-callout-title {
  margin-bottom: 0.6.rem;
  text-align: center;
}
.ps-callout-description,
.ps-paragraph {
  text-align: left;
  padding: 1rem 1rem;
  padding-bottom: 0.5rem;
  line-height: 1.6;
  /* font-size: 1em; */
  color: #000;
  font-weight: 400;
  word-wrap: break-word;
}
.ps-callout-description {
  background-color: #f5f5dc;
  word-wrap: break-word;
}

/* .ps-paragraph, */
.ps-callout-container {
  margin-bottom: 2rem;
}
.ps-callout-container:first-child .ps-callout-title {
  /* font-style: italic; */
}
.ps-videos-caption,
.ps-images-caption,
.ps-audios-caption {
  margin-top: 0.5rem;
  font-size: clamp(12px, 1rem, 22px);
}

.ps-image-slider-container-wrapper img {
  width: 100%;
  object-fit: cover;
  height: 35vw;
}

@media only screen and (max-width: 768px) {
  /*.rhap_progress-indicator{
    width: 10px !important;
    height: 10px !important;
    top: -3px !important;
  }*/
  .ps-image-slider-controls button {
    margin-right: 0 !important;
  }
  #nextBtnImg {
    margin-left: 0 !important;
  }
}

#prevBtnImg {
  width: clamp(20px, 3vw, 80px);
  float: left;
}

#nextBtnImg {
  width: clamp(20px, 3vw, 80px);
  margin-left: 10px;
  float: right;
}

@media (min-width: 426px) and (max-width: 1023px) {
  /* .scroll-to-top img {
    width: clamp(35px, 8vw, 60px) !important;
  } */
}
@media (min-width: 320px) and (max-width: 425px) {
  /* .scroll-to-top img {
    width: 25px !important;
  } */
}
@media (min-width: 1025px) {
  /* .scroll-to-top img {
    width: clamp(60px, 6vw, 130px) !important;
  } */
  /* .ps-image-slider-page-number {
    font-size: 2.2rem;
  } */
}
.ps-image-slider-controls button {
  margin-right: 10px;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  /* padding: 10px; */
}
#videoContainer {
  width: 100%;
  height: 100%;
  width: 100%;
  border: 4px solid rgb(108, 110, 135);
  border-radius: 0.5rem;
  overflow: hidden;
  object-fit: cover;
}
.videosWrapper {
  /* background-color: #6c6e87; */
  width: 100%;
  height: 100%;
  border: none;
  /* border-radius: 5px; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.playBtn {
  max-width: 50%;
  max-height: 50%;
  z-index: 1;
}
.rhap_container {
  height: clamp(20px, 10vw, 100px);
  border-radius: 50px;
  padding: 2vw 0.6vw !important;
  background-color: #e4e4d2 !important;
  outline: 2px solid #6c6d87;
}
.rhap_controls-section {
  flex: initial !important;
  margin-right: 1vw !important;
}
.rhap_main,
.rhap_main-controls {
  height: 100%;
}
.rhap_main-controls {
  justify-content: flex-start;
}
.rhap_progress-section {
  flex: auto !important;
}

.rhap_main-controls button,
.rhap_main-controls button img {
  height: clamp(10px, 8vw, 80px);
  width: clamp(10px, 8vw, 80px);
}
.rhap_main-controls button {
  display: flex;
}
.rhap_progress-indicator {
  height: clamp(10px, 2.5vw, 25px) !important;
  width: clamp(10px, 2.5vw, 25px) !important;
  top: 0 !important;
  background-color: #6c6d87 !important;
  box-shadow: none !important;
}
.rhap_progress-container {
  margin: 0 !important;
}
.rhap_progress-bar,
.rhap_download-progress {
  height: clamp(10px, 2.5vw, 25px) !important;
  border-radius: clamp(5px, 1.25vw, 12.5px) !important;
}
.rhap_progress-filled {
  background-color: #6c6d87 !important;
  border-radius: clamp(5px, 1.25vw, 12.5px) 0 0 clamp(5px, 1.25vw, 12.5px) !important;
}
.rhap_progress-bar {
  margin-right: 1vw;
}

/* @media only screen and (min-width: 600px) and (max-width: 1200px) {
}

@media only screen and (min-width: 1200px) {
} */

/* body {
  background-color: antiquewhite;
} */

.ps-image-slider-container-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ps-image-slider-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  font-size: 24px;
}

.scroll-to-top {
  position: sticky;
  bottom: 2vw;
  height: 6%;
  cursor: pointer;
  width: 100%;
  padding-right: 4%;
}
.scroll-to-top img {
  width: 40px !important;

  /* position: absolute; */
}

@media screen and (max-width: 1024px) {
  .ps-paragraph {
    padding: 1rem 1rem;
  }
  .ps-callout-description {
    padding: 1rem 1rem;
  }
}

@media screen and (max-width: 768px) {
  .ps-content {
    width: 75vw;
  }
}

@media screen and (max-width: 426px) {
  .ps-content {
    width: 80vw;
  }
  .ps-contents-container {
    padding: 1rem 1rem;
  }

  .ps-callout-title {
    margin-bottom: 0.5rem;
  }
}
