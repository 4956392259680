.research-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: clip;
  background-color: #fffaeb;
}
.researchesHeader-container {
  width: 85%;
  border-collapse: collapse;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  column-gap: 1rem;
  align-self: center;
}
.bg-img {
  /* max-height: 40vh; */
  width: 70%;
  margin:auto;
  /* justify-content: center; */
}
.bg-container {
  width: 35%;
  display:flex;
}
.researchHeaderParagraph-container {
  position: relative;
  flex: 20%;
}
.Researches-title {
  font-size: 46px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 1.5rem;
}
.Researches-paragraph {
  font-size: 1.2rem;
  line-height: 1.5;
  font-family: "Open Sans","Noto Serif TC",sans-serif!important;
  padding: 0 4rem;
  text-align: center;
}
.researches-container {
  width: 85%;
  /* max-width: 1200px; */
  /* max-width: 1400px; */
  display: flex;
  flex-flow: row wrap;
  align-self: center;
  margin-bottom: 4%;
}


.loadingPage {
  background-color: #FFFAEB;
}


@media screen and (max-width: 768px) {
  .Researches-title {
    font-size: 32px;
    margin-bottom: 1rem;
  }

  .researchesHeader-container {
    row-gap: 0rem;
    text-align: center;
    flex-direction: column;
  }

  .bg-container {
    width:90%;
  }

  /* .bg-img{
    margin-left: 0%;
  } */

  .Researches-paragraph{
    padding: 0%;
    line-height: 1.2;
  }
}

@media screen and (max-width: 769px) {
  .Researches-paragraph{
    line-height: 1.5
  }

}
