.word1 ,
.word2{
  font-size: 32px;
  margin-inline-start: 2rem;
  margin-block-end: auto;
  margin-top: 1rem;
  font-weight: 700;
  color: #404040;
  margin-bottom: 10px;
}

#profile {
  margin: 30px !important;
  margin-top: 0 !important;
  margin-bottom: 5% !important;
}

.collaborator-content-section {
  position: relative;
  background-image: url("./Group\ 213.2.webp");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom center;
}

.collaborator-content-container {
  width: 75%;
  margin: -21.5rem 5% 5rem 25%;
}

#collaborator-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin:0%;
}

img#cloud {
  max-height: 10%;
  max-width: 20%;
}

#about-title {
  text-align: center;
  font-weight: bold;
  margin: 0;
  font-size: 46px;
}

.collaborator-card-container{
  width: 33.33333333%;
}

@media only screen and (max-width: 768px) {
  .collaborator-content-container {
    width: 100%;
    margin: 15rem 0 0 0;
  }

  .collaborator-content {
    width: 100%;
    margin-top: 80px;
    font-size: 0.8rem;
  }

  .word1,
  .word2 {
    margin-inline-start: 0;
    margin-block-end: 0;
    margin-top: 2rem;
    margin-bottom: 0;
    text-align: center;
    font-size: 28px;
  }
  #about-title {
    font-size: 32px;
  }

  .collaborator-card-container{
    width: 50%;
  }
}
@media only screen and (max-width: 478px){
  .collaborator-card-container{
    width: 100%;
  }

  img#cloud{
    width: 15%;
  }
}


@media only screen and (min-width: 767px) and (max-width: 1203px) {
  .collaborator-card-container{
    width: 50%;
  }
  /* .collaborator-content-container {
    width: 70%;
    margin-left: 25% !important;
    margin-top: -30%;
  } */

  /* .word1,
  .word2 {
    margin-bottom: -1rem;
  } */
}
@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .word1, .word2 {
    font-size: 1.6rem;
    margin-inline-start: 1rem;
    margin-top: 2rem;
  }
  
  #profile {
    margin: 20px !important;
    margin-bottom: 3%;
  }
  
  /* .collaborator-content-container {
    width: 70%;
    margin-left: 25% !important;
    margin-top: -30%;
    padding: 5rem 3rem;
  } */
  
  img#cloud {
    max-height: 7%;
    max-width: 15%;
  }
/*   
  #about-title {
    font-size: 36px;
  } */
}

@media screen and (min-width: 1600px) and (max-width: 2560px) {
  .collaborator-content-container {
    width: 70%;
    margin-left: 25% !important;
    /* margin-top: -15%; */
  }

}
