.poppage-container{
   display: flex;
   flex-direction: column;
}

.poppage-content{
    height: 38rem;
}

.poppage {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  #poppage-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow-y: auto;
    z-index: 3;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border-radius: clamp(4px, 0.8vw, 10px);
    justify-content: center;
    display: flex;
  }
  
  #poppage-popup-img {
    width: 100%;
  }
  
  #poppage-popup-text {
    position: absolute;
    top: 55%;
    /* left: 27%; */
    z-index: 1;
    font-weight: bold;
    font-size: 22px;
  }
  
  
  #poppage-popup-button {
    position: absolute;
    top: 75%;
    z-index: 1;
    width: 10rem;
    height: 2rem;
    background-color: #e8c86a;
    border-radius: clamp(4px, 0.5vw, 10px);
    font-size: 1rem;
    border-color: transparent;
    color: white;
  }

  @media screen and (max-width: 768px) {
    #poppage-popup-text {
        font-size: 1rem;
      }
    
      #poppage-popup-button {
        position: absolute;
        top: 75%;
        z-index: 1;
        width: 8rem;
        height: 2rem;
        background-color: #e8c86a;
        border-radius: clamp(4px, 0.5vw, 10px);
        font-size: 1rem;
        border-color: transparent;
        color: white;
      }

      #poppage-popup {
        width: 85%;
        max-width: 300px;
      }

  }