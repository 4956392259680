.generalPageTitleContainer {
  text-align: center;
  margin-bottom: 50px;
  
}

.generalPageContainer {
  justify-content: center;
  align-items: center;
  width: 82.45%;
  margin: 50px auto;
}


.generalPageTitleInsidegeneralPageTitleContainer {
  font-size: 46px;
}
