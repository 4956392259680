/* body {
  margin: 0;
  padding: 0;
} */

.training_sub_wrapper {
  margin: 0;
  padding: 0;
}

#training-detail-container {
  width: 75vw;
  margin: 3rem auto;
  text-align: center;
}

#training-detail-title {
  width: 75%;
  padding-top: 3.5rem;
  margin: auto;
  display: inline-block;
  justify-content: center;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
}

img#training-detail-image {
  width: 100%;
  margin-bottom: 2vw;
}

#training-detail-card {
  background-color: #eedfa3;
  width: 47.5%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 20px;
}
.training-detail {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

#training-detail-description {
  text-align: left;
}

#training-detail-bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  text-align: left;
}

img.training-detail-card-icon {
  margin-right: 12px;
}

#training-detail-bottom-right {
  width: 47.5%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 24px;
}

button#join-event-button {
  width: 20vw;
  height: 5vw;
  background-color: #e8c86a;
  font-weight: bold;
  border-radius: clamp(4px, 0.8vw, 10px);
  font-size: 40px;
  border-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

#training-sub-pdf-img {
  padding-top: 2vw;
}

.join-event-button-container {
  display: flex;
  align-items: center;
}

@media (max-width: 1024px) {
  button#join-event-button {
    width: 25vw;
    height: 6vw;
  }
}

@media (max-width: 769px) {
  #training-detail-container {
    width: 90vw;
  }

  img#training-detail-image {
    height: auto;
  }

  #training-detail-card {
    width: 100%;
    height: auto;
  }

  #training-detail-bottom {
    flex-direction: column;
    align-items: center;
  }

  #training-detail-bottom-right {
    width: 100%;
    align-items: center;
  }

  button#join-event-button {
    height: 10vw;
    width: 30vw;
    display: inline-block;
    vertical-align: middle;
  }
}
@media (max-width: 460px) {
  #training-detail-container {
    width: 90vw;
  }

  img#training-detail-image {
    height: auto;
  }

  #training-detail-card {
    width: 100%;
    height: auto;
    padding: 20px;
    row-gap: 10px;
  }

  #training-detail-bottom {
    flex-direction: column;
    align-items: center;
  }

  #training-detail-bottom-right {
    width: 100%;
    align-items: center;
    row-gap: 10px;
  }

  button#join-event-button {
    height: 15vw;
    width: 55vw;
  }
}
@media (max-width: 375px) {
  #training-detail-container {
    width: 90vw;
    margin: 2rem auto;
  }

  img#training-detail-image {
    height: auto;
  }

  #training-detail-card {
    width: 100%;
    height: auto;
    padding: 20px;
    row-gap: 10px;
  }

  #training-detail-bottom {
    flex-direction: column;
    align-items: center;
  }

  #training-detail-bottom-right {
    width: 100%;
    align-items: center;
    row-gap: 10px;
  }

  button#join-event-button {
    height: 50px;
  }
}
