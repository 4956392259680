html {
  font-size: 14px;
}

.user-profile-header {
  background-color: #fffaeb;
}

.user-profile-change-alert {
  width: 100vw;
  position: fixed;
  top: 6rem;
  display: flex;
  justify-content: center;
  font-size: 25px;
  text-align: center;
}

.user-profile-change-alert > p {
  width: auto;
  height: auto;
  padding: 0.8126rem 3.676rem;
  border-radius: 99rem;
  color: white;
}

.user-profile-container {
  display: flex;
  justify-content: center;
  background-color: #fffaeb;
  /* padding: 10%;
  padding-top: 50px;
  padding-bottom: 50px; */
  padding: 3.125rem 2rem;
  padding-top: 0;
}

.user-profile-box {
  max-width: 1060px;
  /* max-height: 1498px; */
  /* display: flex;
  flex-direction: column;
  justify-content: center; */
  width: 100%;
}

.user-profile-box-top {
  /* width: 80vw;
  height: calc(80vw * (783 / 1060));
  max-width: 1041px;
  max-height: 769px; */
  /* padding: 10%;
  padding-top: 22.5%;
  padding-bottom: 5%; */
  display: flex;
  flex-direction: column;
  font-family: "Open Sans", "Noto Serif TC", sans-serif;
  border-radius: 0.75rem;
  /* background-image: url(User\ Profile-bg\ desktop-piece1.webp);
  background-size: contain;
  background-repeat: no-repeat; */
}

.user-profile-box-top-bar {
  display: flex;
  min-height: 5.5rem;
  margin-bottom: 0px;
  justify-content: center;
  align-items: center;
  background: #f5f5dc;
}

.user-profile-box-top-title {
  font-weight: 400;
  font-size: 2rem;
  line-height: 131%;
  margin: 0;
  text-align: center;
}

.user-profile-box-top-body {
  background: #f4e1ab;
  padding: 3.21rem 2rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.user-profile-box-para {
  margin-top: 1.5625rem;
  margin-bottom: 0px;
  padding-left: 1rem;
  font-weight: 400;
  font-size: 1.5625rem;
  line-height: 170%;
}
.user-profile-box-para:first-child {
  margin-top: 0;
}

.user-profile-box-input {
  width: 100%;
  /* height: 50px; */
  border: none;
  border-radius: 30px;
  margin-bottom: 10px;
  padding: 0.5em 1em;
  font-weight: 400;
  font-size: 1.375rem;
  line-height: 170%;
  direction: ltr;
}

.user-profile-box-input-nickname {
  /* width: 40%; */
}

.user-profile-alert {
  position: relative;
  margin-bottom: 0px;
  padding-left: 1rem;
  text-align: left;
  font-size: 1rem;
  color: red;
}

.user-profile-top-alert {
  top: -2%;
}

.user-profile-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5625rem;
}

.user-profile-box-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  box-sizing: border-box;
  font-style: normal;
  font-weight: 400;
  font-size: 1.4375rem;
  line-height: 1.5rem;
  letter-spacing: -0.02em;
  color: #f5f3ed;
  background: #e2c067;
  border-radius: 4px;
  width: 100%;
}

.user-profile-box-top-button {
  /* width: 160px;
  height: 58px; */
  padding: 0.8126rem 3.676rem;
}

.user-profile-box-bottom-title {
  /* max-height: 42px; */
  margin-top: 1.875rem;
  font-weight: 700;
  font-size: 2rem;
  line-height: 131%;
  text-align: center;
}

.user-profile-box-bottom {
  /* width: 80vw;
  height: calc(80vw * (602 / 1060)); */
  /* max-width: 1041px;
  max-height: 591px; */
  /* margin-bottom: 5%; */
  /* padding: 10%;
  padding-top: 0%;
  padding-bottom: 0%; */
  background: #f4e1ab;
  padding: 3.21rem 2rem;
  display: flex;
  flex-direction: column;
  font-family: "Open Sans", "Noto Serif TC", sans-serif;
  border-radius: 0.75rem;
  border-top: 1rem solid #e2c067;
  /* background-image: url(User\ Profile-bg\ desktop-piece2.webp);
  background-size: contain;
  background-repeat: no-repeat; */
}

.user-profile-box-bottom-input-container {
  /* height: 7%; */
  position: relative;
}

.user-profile-box-input-password {
  padding-right: 4.2705rem;
}

.user-profile-bottom-alert {
  top: 3%;
}

.user-profile-box-bottom-img {
  width: 2.0625rem;
  height: auto;
  float: right;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.user-profile-box-bottom-button {
  /* width: 220px;
  height: 58px; */
  /* margin-top: 5%;
  margin-bottom: 1%; */
  padding: 0.8126rem 3.676rem;
}

/* Responsive area */
/* Small devices (landscape phones, 576px and up) */
@media only screen and (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  html {
    font-size: 16px;
  }
  .user-profile-container {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .user-profile-box-top-body,
  .user-profile-box-bottom {
    padding-left: 3.21rem;
    padding-right: 3.21rem;
  }
  .user-profile-box-button {
    width: auto;
  }
}

/* Large devices (desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .user-profile-container {
    padding-left: 12rem;
    padding-right: 12rem;
  }
  .user-profile-box-top-body,
  .user-profile-box-bottom {
    padding-left: 6.43rem;
    padding-right: 6.43rem;
  }
}

/* X-Large devices (large desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}

/* XX-Large devices (larger desktops, 1400px and up) */
@media only screen and (min-width: 1400px) {

}

/* @media only screen and (max-width: 500px) {
  .user-profile-container {
    padding: 3%;
  }

  .user-profile-box {
    min-width: 320px;
    min-height: 800px;
    padding: 1%;
  }

  .user-profile-box-button {
    font-size: 22px;
  }

  .user-profile-box-top {
    width: 90vw;
    height: calc(90vw * (775 / 637));
    min-width: 310px;
    min-height: 395px;
    min-width: 317px;
        min-height: 386px;
    padding-top: 38%;
    padding-bottom: 1%;
    background-image: url(User\ Profile-bg\ mobile-piece1-1.webp);
    background-size: cover;
  }

  .user-profile-box-top-title {
    height: 17%;
  }

  .user-profile-box-para {
    margin-top: 3%;
    font-size: 18px;
    line-height: 130%;
  }

  .user-profile-box-input {
    height: 30px;
    font-size: 19px;
  }

  .user-profile-box-input-nickname {
    width: 70%;
  }

  .user-profile-alert {
    font-size: 12px;
  }

  .user-profile-top-alert {
    top: -2%;
  }

  .user-profile-box-top-button {
    width: 140px;
    height: 45px;
  }

  .user-profile-box-bottom-title {
    font-size: 28px;
  }

  .user-profile-box-bottom {
    width: 90vw;
    height: calc(90vw * (602 / 637));
    min-width: 310px;
    min-height: 380px;
    padding-top: 5%;
    padding-bottom: 0%;
    background-image: url(User\ Profile-bg\ mobile-piece1.webp);
    background-size: cover;
  }

  .user-profile-box-input-password {
    padding-right: 15%;
  }

  .user-profile-box-bottom-img {
    width: 25px;
    height: calc(25px * (28 / 33));
    top: -35px;
    right: 5%;
  }

  .user-profile-box-bottom-button {
    width: 185px;
    height: 45px;
    margin-top: 10%;
    margin-bottom: 3%;
  }
} */

/* @media only screen and (min-width: 501px) and (max-width: 768px) {
  .user-profile-container {
    padding-left: 13%;
    padding-right: 13%;
  }

  .user-profile-box {
    min-width: 433px;
    min-height: 1058px;
  }

  .user-profile-box-top {
    width: 74vw;
    height: calc(74vw * (775 / 637));
    min-width: 430px;
    min-height: 523px;
    padding-top: 38.5%;
    padding-bottom: 1%;
    background-image: url(User\ Profile-bg\ mobile-piece1-1.webp);
  }

  .user-profile-box-top-title {
    height: 17%;
  }

  .user-profile-box-para {
    font-size: 23px;
    line-height: 160%;
  }

  .user-profile-box-input {
    height: 45px;
  }

  .user-profile-box-input-nickname {
    width: 50%;
  }

  .user-profile-box-bottom {
    width: 74vw;
    height: calc(74vw * (602 / 637));
    min-width: 430px;
    min-height: 406px;
    padding-top: 7%;
    padding-bottom: 1%;
    background-image: url(User\ Profile-bg\ mobile-piece1.webp);
  }

  .user-profile-box-input-password {
    padding-right: 16%;
  }

  .user-profile-box-bottom-img {
    top: -46px;
    right: 5%;
  }

  .user-profile-bottom-alert {
    top: 5%;
  }

  .user-profile-box-bottom-button {
    margin-top: 3%;
  }
} */

/* @media only screen and (min-width: 769px) and (max-width: 992px) {
  .user-profile-container {
    padding-left: 5%;
    padding-right: 5%;
  }

  .user-profile-box-top {
    width: 90vw;
    height: calc(90vw * (783 / 1060));
    padding-bottom: 1%;
  }

  .user-profile-box-top-title {
    height: 16.5%;
  }

  .user-profile-box-para {
    margin-top: 2%;
    font-style: 23px;
  }

  .user-profile-box-input {
    height: 36px;
    font-size: 20px;
  }

  .user-profile-alert {
    font-size: 14px;
  }

  .user-profile-box-top-button {
    width: 150px;
    height: 50px;
  }

  .user-profile-box-bottom {
    width: 90vw;
    height: calc(90vw * (602 / 1060));
  }

  .user-profile-box-bottom-img {
    top: -42px;
  }

  .user-profile-box-bottom-button {
    margin-top: 4%;
  }
} */

/* @media only screen and (min-width: 993px) and (max-width: 1200px) {
  .user-profile-container {
    padding-left: 10%;
    padding-right: 10%;
  }

  .user-profile-box-top {
    width: 80vw;
    height: calc(80vw * (783 / 1060));
    padding-bottom: 1%;
  }

  .user-profile-box-bottom {
    width: 80vw;
    height: calc(80vw * (602 / 1060));
  }

  .user-profile-box-input-password {
    padding-right: 9%;
  }

  .user-profile-bottom-alert {
    top: 4%;
  }
} */
