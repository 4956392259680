.person-info-card {
  background-color: #e4e4d2;
  margin-bottom: 10px;
  margin-top: 30px;
  /* aspect-ratio: 1 / 2; */
  text-align: left;
  border-radius: 1%;
  height: auto;
}

.person-info-card:hover {
  box-shadow: 0 8px 1rem 0 rgba(0, 0, 0, 0.24), 0 4px 8px 0 rgba(0, 0, 0, 0.16);
}

.info-card-content {
  margin: auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-image {
  aspect-ratio: 1;
  border-radius: 50% !important;
  object-fit: cover;
}

.card-title,
.card-text-position,
.card-image,
.card-more-info {
  width: 100%;
}

.card-title {
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  font-size: 22px;
  font-weight: 700;
  /* height: 4rem; */
}

.card-text-position {
  font-size: 1rem;
  font-weight: 500;
  height: auto;
  margin-bottom: 1rem;
  /* overflow: auto; */
}

.card-text-education{
  height: fit-content;
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
  margin-bottom: 1rem;
}

a.card-more-info {
  text-decoration: none;
  color: #306188;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}
@media screen and (max-width: 768px) {

  .col-md-6 {
    width: 100% !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .person-info-card {
    height: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .info-card-content {
    padding-top: 10%;
    padding-bottom: 10%;
    width: 80%;
  }

  .card-image {
    width: 90%;
  }

  .card-title {
    font-size: 1.2rem;
  }

  .card-text-position {
    font-size: 0.9rem;
  }

  .card-text-education{
    font-size: 0.9rem;
  }

  a.card-more-info {
    margin-bottom: 3rem;
  }
}

@media only screen and (max-width: 375px){
  .person-info-card {
    height: auto;
  }
}

@media screen and (min-width: 1940px) and (max-width: 2560px) {
  .person-info-card {
    height: auto;
  }
}