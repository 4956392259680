.team-head-content-section {
  position: relative;
  background-image: url("./line.webp");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center 100px;
}

.team-head-container {
  background-color: #f4e1ab;
  padding: 3rem;
  padding-bottom: 1%;
  border-radius: 1rem;
  width: 65%;
  margin: -20rem 0% 3rem 10%;
  margin-left: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.6;
  text-align: left;
}

.team-head-subtitle {
  margin-top: 3rem;
}

.teams_content_manage {
  max-width: 70%;
  margin-bottom: 1rem;
  line-height: 1.6;
}

.active-card {
  /* display: flex;
  flex-wrap: wrap; */
  max-width: 420px;
  height: 10rem;
  width: 95%;
  aspect-ratio: 4 / 1;
  margin: 0 1rem;
  padding: 1rem 1.5rem;
  padding-right: 0.5rem;
  /* overflow: hidden; */
  background-image: url("./Rectangle\ 15.webp");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  text-align: start;
}

.active-card-content{
  height: 100%;
  overflow: auto;
  padding-right: 1rem;
}


.active-card-content::-webkit-scrollbar {
  width: 5px;
  display: block;
}

.active-card-content::-webkit-scrollbar-thumb {
 background-color:rgba(179, 176, 174, 0.8);
  border-radius: 5px;
}

.info-card {
  text-align: left;
  font-size: 1rem;
  background-color: #f5f5dc;
  width: 70%;
  /* max-width: 400px; */
  margin: 0 auto;
  padding: 1rem;
  box-sizing: border-box;
  margin-bottom: 2.2rem;
  word-break: break-all;
}

.info-card-address{
  word-break: normal;
}

.info-card > div {
  /* max-width: 25rem; */
  margin: 0.25rem 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.team-head-content {
  max-width: 70%;
  text-align: center;
}

a.team-head-more-info {
  text-decoration: none;
  color: #306188;
  font-size: 1rem;
}

img.info-icon {
  width: 20px;
  height: 20px;
}

img.team-head-icon {
  /* height: 20rem;
  width: 20rem; */
  width: 100%;
  border-radius: 1rem;
  object-fit: cover;
  /* border: 1px solid black; */
  background-color: lightgray;
  margin-bottom: -1.5rem;
  max-width: 70%;
}

img.arrow {
  height: 40px;
}

.info-card > div > span {
  margin: 0;
  font-size: 1rem;
}

.slide-position-display-container {
  position: relative;
  font-size: 1.5rem;
  align-items: center;
  text-align: center;
}

.slide-position-display-container img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.slide-position-display {
  position: relative;
  z-index: 5;
}

.arrow-button {
  border: none;
  background-color: transparent;
}

@media only screen and (max-width: 768px) {
  .info-card {
    font-size: 0.8rem;
    width: 90%;
  }
  .team-head-content-section {
    background-position: center 200px;
  }

  .team-head-container {
    width: 80%;
    margin-top: 15rem;
    margin-left: 10%;
    padding: 1rem;
  }

  img.team-head-icon {
    /* height: 240px;
    width: 240px; */
  }

  .active-card {
    /* display: flex;
    flex-wrap: wrap; */
    /* max-width: 90%; */
    /* height: 12rem;
    overflow: auto;
    width: 90%;
    aspect-ratio: 4 / 1;
    margin: 0 1rem;
    padding: 1.5rem;
    background-image: url("./Rectangle\ 15.webp");
    background-size: 100% 100%;
    background-repeat: no-repeat; */
    padding: 0.5rem 1.5rem;
  }

  .teams_content_manage {
    max-width: 90%;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1200px) {
  img.team-head-icon {
    /* height: 250px;
    width: 250px; */
  }

  .team-head-container {
    margin-top: -20rem;
    width: 70%;
    margin-left: 28%;
  }
  .active-card {
    /* display: flex;
    flex-wrap: wrap; */
    /* max-width: 40rem; */
    /* height: 11.5rem; */
    width: 90%;
    aspect-ratio: 4 / 1;
    margin: 0 1rem;
    padding: 1.5rem;
    overflow: auto;
    background-image: url("./Rectangle\ 15.webp");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}

@media only screen and (min-width: 1700px) {
  .team-head-content-section {
    background-size: 100% 100%;
  }

  .team-head-container {
    margin-top: -20rem;
  }
}
