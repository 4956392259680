#header-top {
  height: 100px;
  width: 100%;
  padding: 0 10vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #f5df8f; */
}

#header-top-left {
  width: 30%;
  display: flex;
  align-items: center;
}

#header-top-left > a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

#header-top-left>p {
  margin: 0;
  font-weight: 500;
}

img.site-logo {
  max-height: 5rem;
  margin-right: 1rem;
}

img#header-icon {
  /* min-height: 3rem;
  min-width: 10rem; */
  /* max-width: 300px; */
  /* width: 18.75rem; */
  width: 13.1875rem;
  /* max-height: 5rem; */
  margin-right: 1rem;
}

#header-top-right {
  display: flex;
  align-items: center;
  column-gap: 24px;
}

.header-top-right-button {
  width: 160px;
  height: 65px;
  box-sizing: border-box;
  border: none;
  border-radius: 4px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  border-radius: 5px;
  overflow: hidden;
}

.header-top-right-button button {
  width: 150px;
  height: 65px;
  border: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #e2c067;
}

.header-top-right-button-container span {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid #e2c067;
}

button.login-button {
  /* width: 150px; */
  /* height: 60px; */
  font-size: 1.4rem;
  /* margin-left: 12px; */
  background-color: #e2c067;
}

.loginBtn-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.header-top-right-button-container {
  width: 150px;
  height: 140px;
  position: absolute;
  top: 3.125rem;
  /* margin-left: 10px; */
  display: grid;
  grid-template-rows: 1fr 3fr 3fr;
  justify-items: center;
  font-size: 1.4rem;
  z-index: 9999;
}

.header-top-right-button-container a button {
  width: 150px;
  height: 100%;
  border-style: solid;
}

.header-top-right-button-container button {
  width: 150px;
  border-style: solid;
  border-color: #e2c067;
  background-color: white;
}

.social-icon-header{
  max-width: 1.5rem;
  aspect-ratio: 1/1;
}

#header-social-icon-container {
  display: flex;
  column-gap: 1rem;
}

img.header-social-icon {
  width: 24px;
  height: 24px;
}

img.header-social-icon:hover {
  cursor: pointer;
}

button.login-button {
  /* width: 150px; */
  /* height: 60px; */
  font-size: 1.4rem;
  /* margin-left: 12px; */
  background-color: #e2c067;
  border: 0px;
  color: #F5F3ED;
  border-radius: 2px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0.5rem 1.5rem;
}

#header-bottom {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #f5df8fb3;
  margin-bottom: 1rem;
}

#header-menu-container {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hrColor {
  /* color: #E2C067; */
  border: 2px solid #E2C067;
}

#content-header-expansion-container {
  display: block;
  position: relative;
}

#content-header-mobile-expansion {
  display: flex;
  flex-direction: row;
  margin-top: 1vh;
  align-items: center;
}

.languageBtn {
  border: none;
  color: #306188;
  background-color: transparent;
  font-size: 16.62px;
  font-weight: 400;
}

#content-bar-expansion {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

#expand-simple-menu{
  background-color: transparent;
  font-family: "Open Sans","Noto Serif TC",sans-serif!important;
}
/* #FAEECCCC */
/* div:has(> .menuBtn:hover){
  background-color: #000000 !important; 
  text-transform: underline !important; 
} */
.menuBtn{
  background-color: transparent !important;
}
.menuBtn:hover{
  background-color: #FAEECCCC !important;
}
.menuBtn:hover>:first-child{
  text-decoration: underline !important; 
}

#submenu {
  font-family: "Open Sans","Noto Serif TC",sans-serif;
  display: flex;
  align-items: center;
}

.subMenu-img {
  max-width: 15px;
  max-height: 15px;
  margin-right: 15px;
}

.expandMenuItem{
  font-family: "Open Sans","Noto Serif TC",sans-serif!important;
}

/* #expand-simple-menu{
  overflow: hidden!important;
} */



@media screen and (min-width: 769px) and (max-width: 1150px) {
  #header-menu-container>div>button>span {
    font-size: 1.7vw !important;
  }

  #header-top {
    padding: 0 2rem 0 2rem;
  }
}
@media only screen and (max-width: 769px) {
  #header-top {
    padding-left: 1rem;
    padding-right: 4rem;
  }
}
@media only screen and (max-width: 768px) {
  #header-top {
    height: 100px;
    font-size: 0.8rem;
    /* justify-content: center; */
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .header-top-right-button-container {
    width: 130px;
    justify-content: center;
  }

  .header-top-right-button-container a button {
    width: 130px;
    border-style: solid;
    border-color: #e2c067;
    background-color: white;
  }

  .header-top-right-button-container {
    width: auto;
    /* right: 0px; */
  }

  .header-top-right-button-container > a {
    font-size: 1rem;
  }

  .header-top-right-button-container > a > button {
    width: 5rem;
  }

  .header-top-right-button-container > button {
    width: 5rem;
    font-size: 1rem;
    
  }
  
  #header-top-left {
    width: 60%;
  }

  #header-bottom {
    margin-top: 0px;
  }

  img#header-icon {
   min-width:auto;
   min-height:auto;
   /* width: 160px; */
  }

  #header-top-right {
    width: 30%;
    flex-direction: column-reverse;
    row-gap: 5px;
    column-gap: 0;
  }

  .header-top-right-button {
    width: 140px;
    height: 40px;
  }

  .header-top-right-button button {
    height: 40px;
  }

  #header-menu-expansion {
    padding: 0 3vw;
    width: 100%;
    text-align: center;
    /* background-color: #f4e2ad; */
    color: #404040;
    font-weight: 400;
    font-size: 18px;
  }

  #header-menu-container {
    display: block;
    width: 100%;
    /* background-color: #f4e2ad; */
  }

  #header-menu-container>div>button {
    width: 100%;
    display: flex;
    justify-content: center;
    color: white;
  }

  #header-social-icon-container {
    column-gap: 5vw;
    justify-content: center;
    flex: 1;
  }

  img.header-social-icon {
    width: 2rem;
    height: 2rem;
  }

  button.login-button {
    margin-left: 6px;
    /* width: 5rem;
    height: 35px; */
    font-size: 1.2rem;
    border: 0px;
    color: #F5F3ED;
    border-radius: 2px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .loginBtn-container {
    flex: 1;
    text-align: right;
  }

  #languageBtn-container {
    flex: 1;
    text-align: center;
  }

  .menuBtn:hover{
    background-color: #FFFAEB80 !important;
  }

  .menuBtn:focus{
    background-color: #FFFAEB80 !important;
  }

  .menuBtn:focus + div  {
    background-color: #FFFAEB80 !important;
  }

  .expandMenuItem:hover {
    text-decoration: underline !important;
  }

  .expandMenuItem {
    font-size: 1rem !important;
    width: 100% !important;
  }
}

/* @media only screen and (min-width: 769px) and (max-width: 1440px) {
  #header-top {
    padding: 0 3rem 0 3rem;
  }
} */

/* @media only screen and (min-width: 769px) and (max-width: 1040px) {
  #header-menu-container div {
    flex-grow: 1;
  }
} */

@media only screen and (max-width: 426px) { 
  #header-top {
    padding-left: 1rem;
  }

  img#header-icon {
    /* max-width: 50px;
    max-height: 60px; */
    /* width: 180px; */
    width: 15rem;
    margin-right: 8px;
    height: auto;
  }
}