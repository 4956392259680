.recruitment-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 65%;
}

.recruitment-title{
    font-size: 46px;
    font-weight: bold;
    margin-top: 2rem;
    margin-bottom: 40px;
}

.recruitment-title-paragragh{
    font-size: 1rem;
    font-weight: 400;
    width:90%;
    margin:auto;
    margin-bottom: 1rem;
    line-height: 1.6;
}


.recruitment-list-title{
    display:grid;
    grid-template-columns:25vw 45vw;
    grid-auto-flow: row;
    background-color: rgba(232,200,106,0.5);
    justify-content: center;
    align-items: center;
    font-size: 22px;
    font-weight:bold;
    border-radius: 5px;
    margin-bottom: 15px;
}

.recruitment-list-card {
  display: grid;
  grid-template-columns: 25vw 45vw;
  grid-auto-flow: row;
  background-color: rgba(232, 200, 106, 0.5);
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 5px;
}

.publish-date {
  padding: 1rem 15%;
  padding-right: 0%;
}

.job-title {
  padding: 1rem 15%;
  padding-left: 0%;
}

.recruitment-list {
  margin-bottom: 60px;
}

.recruitment-more-information-title{
    background-color: rgba(236, 183, 24, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 15%;
    font-size: 32px;
    font-weight: bold;
    border-radius: 10px;
    margin-bottom: 20px;
}

.recruitment-e-mail {
  width: 460px;
  background-image: url("./email.webp");
  background-size: 100%;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 150px;
  margin-bottom: 40px;
}

.recruitment-e-mail-content a {
  font-size: 22px;
  position: relative;
  top: 3.8rem;
  left: 10%;
  color: #306188;
}

@media (max-width: 768px) {
    .recruitment-title{
        font-size: 32px;
    }

    .recruitment-list-title{
        font-size: 1rem;
        grid-template-columns:40vw 40vw;
    }

    .recruitment-more-information-title{
        font-size: 22px;
    }

  .recruitment-list-card {
    font-size: 0.8rem;
    grid-template-columns: 40vw 40vw;
  }

  .recruitment-title-paragragh {
    font-size: 1rem;
  }

  .recruitment-container {
    width: 75%;
  }

  .recruitment-e-mail{
    width: 300px;
    height: 100px;
  }

  .recruitment-e-mail-content a {
    font-size: 16px;
    top: 2.4rem;
  }
}
/* 
@media (max-width: 375px) {
  .recruitment-e-mail-content a {
    top: 5vw;
  }
} */
