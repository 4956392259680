.counselor-card {
  width: 30%;
  text-align: left;
  margin: 1.5%;
  box-sizing: border-box;
}

.counselor-card-upper {
  background-color: #e4e4d2;
  padding: 10%;
  padding-bottom: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  height:auto;
}

.counselor-title {
  margin-top: 2rem;
  font-weight: 700;
  font-size: 22px;
  /* height: 4rem; */
  margin-bottom: 1.5rem;
}

.counselor-text-position {
  font-weight: 500;
  font-size: 1rem;
  /* height:4rem; */
  margin-bottom: 1rem;
}

.counselor-text-education {
  font-weight: 500;
  font-size: 1rem;
  /* height:4rem; */
  margin-bottom: 1rem;
}

.counselor-text-information{
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 1rem;
  /* height: 3rem; */
  word-break: break-all;
}

.counselor-text-number{
  font-weight: 500;
  font-size: 1rem;
  /* height: 2rem; */
  margin-bottom: 0rem;
  word-break: break-all;
}

img.counselor-image {
  width: 70%;
  aspect-ratio: 1;
  border-radius: 50%;
  margin: 0 auto;
  object-fit: cover;
}

.counselor-content-container {
  background-color: #e4e4d2;
  padding: 0 5% 0 10%;
  margin-top: 2px;
  border-radius: 5px;
  padding-bottom: 10px;
}

.counselor-content-container:hover {
  background-color: #f5f5dc;
}

.counselor-content-expansion {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
}

.counselor-content-expansion > h5 {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 1rem;
}

.counselor-content{
  padding-bottom: 10%;
  padding-right:8%;
  height:25rem;
  overflow: auto;
}

.counselor-content::-webkit-scrollbar{
  width: 5px;
}

.counselor-content::-webkit-scrollbar-thumb{
  background-color:rgba(179, 176, 174, 0.8);
  border-radius: 5px;
}

/* ::-webkit-scrollbar{
  width: 5px;
} */
/* ::-webkit-scrollbar-thumb {
  background-color:rgba(179, 176, 174, 0.8);
  border-radius: 5px;
} */



img.expand-icon {
  height: 30px;
  aspect-ratio: 1;
}

img.counselor-card-icon {
  width: 20px;
  height: 20px;
  margin-right: 4px;
}

@media only screen and (max-width: 768px) {
  .counselor-card {
    width: 47%;
    margin: 10% auto;
  }
  .counselor-card-upper {
    height:auto;
  }
  /* .counselor-title {
    font-size: 1.4rem;
  } */
}

@media only screen and (max-width: 478px){
  .counselor-card{
    width: 90%;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1200px) {
  .counselor-card {
    width: 47%;
    margin-bottom: 5%;
  }

  .counselor-card-upper {
    height:auto;
  }
  .counselor-title {
    font-size: 1.4rem;
  }
}