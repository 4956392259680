/* body {
  overflow: auto;
} */

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.about-us-content-section {
  background-image: url("./Group\ 258.webp");
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
}

.about-us-container {
  width: 85%;
  margin-left: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 5rem; /* 5% margin added */
}

.about-us-content {
  background-color:#F4E1AB;
  padding: 2rem;
  border-radius: 10px;
  max-width: 70%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.about-us-title {
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 46px;
}

.about-us-paragraph {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: left;
}

.about-us-image {
  max-width: 100%;
}

img.about-us {
  width: 100%;
  object-fit: cover;
}



/* @media only screen and (min-width: 1200px) {
  .about-us-content {
    text-align: right;
  }
} */
/* 
body {
  background-color: antiquewhite;
  text-align: right;
} */

@media only screen and (min-width: 1500px) and (max-width: 1900px){
  .about-us-container {
    margin-top: 0%;
  }
}

@media only screen and (min-width: 1900px) and (max-width: 2560px){
  .about-us-container {
    margin-top: 5%;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1400px){
  .about-us-container {
    margin-top: -5rem;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1000px) {
  .about-us-content-section {
    background-position: center 0rem;
  }

  .about-us-container {
    font-size: 1rem;
    margin-top: -9rem;
  }
}

@media only screen and (max-width: 768px) {
  .about-us-content-section {
    background-position: center 12rem;
  }

  .about-us-container {
    width: 100%;
    margin-left: 0%;
    margin-top: 22rem;
    font-size: 0.8rem;
    flex-wrap: wrap;
    align-content: center;
  }

  .about-us-content {
    max-width: 80%;
    margin-left: 5%;
    margin-right: 5%;
  }

  .about-us-content-section {
    position: relative;
  }

  .about-us-title{
    font-size: 32px;
  }
}

@media only screen and (min-width: 427px) and (max-width: 640px) {
  .about-us-container {
    margin-top: 20rem;
  }
  .about-us-content-section {
    background-position: center 12rem;
  }

}

@media only screen and (min-width: 375px) and (max-width: 426px) {
  .about-us-container {
    margin-top: 18rem;
  }
  .about-us-content-section {
    background-position: center 12rem;
  }

}

@media only screen and (max-width: 374px) {
  .about-us-content-section {
    background-position: center 12rem ;
  }

  .about-us-container {
    margin-top: 16rem;
  }
}